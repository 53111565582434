import bar from "../../assets/images/bar.svg";
import cIcon from "../../assets/images/table_icon.svg";
import fluent from "../../assets/images/info_icon.svg";
import carbonImg from "../../assets/images/image_icon.svg";
import pepicon from "../../assets/images/full_view_icon.svg";
import compare from "../../assets/images/compare_icon.svg";
import shrink from "../../assets/images/shrink.png";
import menuO from "../../assets/images/menu-o.png";
import menuC from "../../assets/images/menu-c.png";
import DropBox from "../dropBox";

import { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";

import {
  fetchAllUniqueByPost,
  fetchByCompanyPost,
  fetchCompanyList,
} from "../../services/home";
import { useSelector } from "react-redux";
import * as echarts from "echarts"; // Import ECharts library
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Shimmer from "../shimmer";
import html2canvas from "html2canvas";
import moment from "momnet";

const ByCompanyCard = ({ startDate, endDate }) => {
  const chartRef = useRef(null);

  const refreshValue = useSelector((state) => state.counter.value);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [compareModel, setCompareModel] = useState(false);
  const [title, setTitle] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isExpand, setIsExpand] = useState(false);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany1, setSelectedCompany1] = useState("");
  const [selectedCompany2, setSelectedCompany2] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleInfo = () => {
    setInfoVisible(!isInfoVisible);
  };
  const showExpand = () => {
    setIsExpand(!isExpand);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [chartState, setChartState] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },
      legend: {
        // Try 'horizontal'
        orient: "horizontal",
        bottom: 0,
        // top: 20,
      },
      grid: {
        left: "3%",
        right: "4%",
        containLabel: true,
        bottom: 35,
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {},
      //   },
      // },
      xAxis: {
        type: "category",
        data: [],
        triggerEvent: true,
        axisLabel: {
          interval: 0,
          rotate: 20, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "value",
        splitLine: { show: false },
      },
      series: [
        {
          name: "Unique",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Repeat",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                console.log("X: " + this.x + ", Y: " + this.y);
                //call function passing this values as arguments
              },
            },
          },
        },
      },
    },
  });

  const [compareStartDate1, setCompareStartDate1] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate1, setCompareEndDate1] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading1, setCompareLoading1] = useState(false);
  const [compareChartState1, setCompareChartState1] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 20, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "value",
        splitLine: { show: false },
      },
      series: [
        {
          name: "Unique",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          id: "Jdofj",
          data: [],
        },
        {
          name: "Repeat",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                console.log("X: " + this.x + ", Y: " + this.y);
                //call function passing this values as arguments
              },
            },
          },
        },
      },
    },
  });

  const [compareStartDate, setCompareStartDate] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate, setCompareEndDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading, setCompareLoading] = useState(false);
  const [compareChartState, setCompareChartState] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {},
      //   },
      // },
      xAxis: {
        type: "category",
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 20, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "value",
        splitLine: { show: false },
      },
      series: [
        {
          name: "Unique",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          id: "Jdofj",
          data: [],
        },
        {
          name: "Repeat",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                console.log("X: " + this.x + ", Y: " + this.y);
                //call function passing this values as arguments
              },
            },
          },
        },
      },
    },
  });

  // Event handler for series click
  const handleSeriesClick = (params) => {
    if (params.seriesType === "bar") {
      handleShow();
      setTitle(params.name);

      getByPost(params.data.id);
      // Handle click event for bar series
      console.log("Clicked on bar series:", params);
    }
  };

  const [detailLoading, setDetailLoading] = useState(true);
  const [detailState, setDetailState] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "10%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: ["M1", "M2", "M3", "M4"],
        },
      ],
      yAxis: [
        {
          type: "value",
          splitLine: { show: false },
        },
      ],
      series: [
        {
          name: "Unique",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Repeat",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    },
  });

  // const [chartState, setChartState] = useState({
  //   options: {
  //     tooltip: {
  //       trigger: "axis",
  //       axisPointer: {
  //         type: "shadow",
  //       },
  //     },
  //     xAxis: {
  //       type: "category",
  //       axisTick: { show: false },
  //       axisLabel: {
  //         interval: 0,
  //         rotate: 30,
  //       },
  //       splitLine: {
  //         show: false,
  //       },
  //       data: [],
  //     },
  //     yAxis: {
  //       type: "value",
  //     },
  //     series: [
  //       {
  //         name: "Forest",
  //         type: "bar",
  //         barGap: 0,
  //         // label: labelOption,
  //         emphasis: {
  //           focus: "series",
  //         },
  //         data: [320, 332, 301, 334, 390, 33, 3, 333, 33333, 333, 33333, 33],
  //       },
  //     ],
  //   },
  // });

  // const getByCompanyPost = async () => {
  //   setLoading(false);
  //   const payload = {
  //     handler:
  //       JSON.parse(localStorage.getItem("company_filter"))?.map(
  //         (value) => value.id
  //       ) ?? [],
  //     themes:
  //       JSON.parse(localStorage.getItem("themes_filter"))?.map(
  //         (value) => value.id
  //       ) ?? [],
  //     startDate: startDate,
  //     endDate: endDate,
  //   };
  //   const res = await fetchByCompanyPost(payload);
  //   if (res.status) {
  //     let companyArray = res.data.map((element) => {
  //       return element.name;
  //     });

  //     const dataLength = res.data.length;
  //     const postCountLength = res.data[0].postCounts.length;
  //     const tempArray = [];
  //     const valueArray = [];

  //     for (let i = 0; i < postCountLength; i++) {
  //       tempArray.push(
  //         res.data.map((value, index) => {
  //           return value.postCounts[i];
  //         })
  //       );
  //     }

  //     tempArray.map((value) => {
  //       valueArray.push({
  //         name: value[0].name,
  //         type: "bar",
  //         barGap: 0,
  //         label: {
  //           show: true,
  //           rotate: 90,
  //           fontSize: 10,
  //           align: "left",
  //           formatter: function (d) {
  //             return `${value[0].name}` + " " + d.data;
  //           },
  //         },
  //         emphasis: {
  //           focus: "series",
  //         },
  //         data: [],
  //       });
  //     });

  //     for (let j = 0; j < tempArray.length; j++) {
  //       for (let i = 0; i < dataLength; i++) {
  //         valueArray[j].data[i] = tempArray[j][i].count;
  //       }
  //     }

  //     console.log(valueArray);

  //     // for (let i = 0; i < postCountLength; i++) {
  //     //   for (let j = i; j < i; j--) {
  //     //     console.log("JDOJFOJ", i, j);
  //     //   }
  //     // }

  //     chartState.options.xAxis.data = companyArray;
  //     chartState.options.series = valueArray.map((value) => {
  //       return value;
  //     });

  //     setChartState(chartState);
  //     setLoading(true);
  //   }
  // };

  const getByCompanyPost = async () => {
    setLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      startDate: startDate,
      endDate: endDate,
      uniqueType: localStorage.getItem("requestType"),
    };
    const res = await fetchByCompanyPost(payload);
    if (res.status) {
      setTableData(res.data);
      let months = res.data.map((value) => {
        return value.name;
      });

      let uniques = res.data.map((value) => {
        return {
          value: value.totalUniquePost,
          name: value.name,
          id: value._id,
        };
      });

      let repeat = res.data.map((value) => {
        return {
          value: value.totalRepeatedPost,
          name: value.name,
          id: value._id,
        };
      });

      console.log("JDOFJ", repeat);

      chartState.options.xAxis.data = months;
      chartState.options.series[0].data = uniques;
      chartState.options.series[1].data = repeat;
      setChartState(chartState);
      setLoading(true);
    }
  };

  const getCompareByCompanyPost1 = async () => {
    setCompareLoading1(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      startDate: compareStartDate1,
      endDate: compareEndDate1,
      uniqueType: localStorage.getItem("requestType"),
    };
    if (selectedCompany1) {
      payload.companyIds = [selectedCompany1];
    }
    const res = await fetchByCompanyPost(payload);
    if (res.status) {
      let months = res.data.map((value) => {
        return value.name;
      });

      let uniques = res.data.map((value) => {
        return {
          value: value.totalUniquePost,
          name: value.name,
          id: value._id,
        };
      });

      let repeat = res.data.map((value) => {
        return {
          value: value.totalRepeatedPost,
          name: value.name,
          id: value._id,
        };
      });

      compareChartState1.options.xAxis.data = months;
      compareChartState1.options.series[0].data = uniques;
      compareChartState1.options.series[1].data = repeat;
      setCompareChartState1(compareChartState1);
      setCompareLoading1(true);
    }
  };

  const getCompareByCompanyPost = async () => {
    setCompareLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      startDate: compareStartDate,
      endDate: compareEndDate,
      uniqueType: localStorage.getItem("requestType"),
    };
    if (selectedCompany2) {
      payload.companyIds = [selectedCompany2];
    }
    const res = await fetchByCompanyPost(payload);
    if (res.status) {
      let months = res.data.map((value) => {
        return value.name;
      });

      let uniques = res.data.map((value) => {
        return {
          value: value.totalUniquePost,
          name: value.name,
          id: value._id,
        };
      });

      let repeat = res.data.map((value) => {
        return {
          value: value.totalRepeatedPost,
          name: value.name,
          id: value._id,
        };
      });

      console.log("JDOFJ", repeat);

      compareChartState.options.xAxis.data = months;
      compareChartState.options.series[0].data = uniques;
      compareChartState.options.series[1].data = repeat;
      setCompareChartState(compareChartState);
      setCompareLoading(true);
    }
  };

  const getByPost = async (cId) => {
    setDetailLoading(true);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      startDate: startDate,
      endDate: endDate,
      companyIds: [cId],
    };
    const res = await fetchByCompanyPost(payload);
    if (res.status) {
      let months = res.data[0].postCounts.map((value) => {
        return value.name;
      });

      let uniques = res.data[0].postCounts.map((value) => {
        return value.unique;
      });

      let repeat = res.data[0].postCounts.map((value) => {
        return value.repeated;
      });

      detailState.options.xAxis[0].data = months;
      detailState.options.series[0].data = uniques;
      detailState.options.series[1].data = repeat;
      setDetailState(detailState);
      setDetailLoading(false);
    }
  };

  const saveAsImage = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
      });
    }
  };

  const getCompanyList = async () => {
    const res = await fetchCompanyList();
    if (res.status) {
      setCompanyList(res.data);
    }
  };

  useEffect(() => {
    getByCompanyPost();
  }, [startDate, endDate, refresh, refreshValue]);

  useEffect(() => {
    getCompareByCompanyPost();
  }, [compareEndDate, compareStartDate, selectedCompany2]);

  useEffect(() => {
    getCompareByCompanyPost1();
  }, [compareEndDate1, compareStartDate1, selectedCompany1]);

  useEffect(() => {
    getCompanyList();
  }, []);

  const handleCompareModelClose = () => {
    setCompareStartDate1(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate1(moment(new Date()).format("yyyy-MM-DD"));
    setCompareStartDate(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate(moment(new Date()).format("yyyy-MM-DD"));
    setSelectedCompany1("");
    setSelectedCompany2("");
    setCompareModel(false);
  };

  return (
    <>
      {isExpand && <DropBox />}

      <div class={`themeCard ${isExpand ? "expand" : ""}`}>
        <div class="CardContainer">
          <header class="cardHeader">
            <h5>By Company</h5>
            <div class="headerRight">
              <ul class="headerRightItems">
                <li onClick={setRefresh}>
                  {" "}
                  <a>
                    <img src={bar} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    setShowTable(!showTable);
                  }}
                >
                  {" "}
                  <a>
                    <img src={cIcon} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    getCompareByCompanyPost();
                    setCompareModel(true);
                  }}
                >
                  {" "}
                  <a>
                    <img src={compare} alt="" />
                  </a>
                </li>
                <li onClick={toggleInfo}>
                  {" "}
                  <a>
                    <img src={fluent} alt="" />
                  </a>
                  {isInfoVisible && (
                    <div className="chartInfo">
                      <div className="infottl">Information</div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                    </div>
                  )}
                </li>
                <li
                  onClick={() => {
                    // setRefresh(!refresh);
                    saveAsImage();
                  }}
                >
                  {" "}
                  <a>
                    <img src={carbonImg} alt="" />
                  </a>
                </li>
                <li
                  className={`${isExpand ? "active" : ""}`}
                  onClick={showExpand}
                >
                  {" "}
                  <a>
                    <img src={isExpand ? shrink : pepicon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </header>
          <div class="card-body">
            {loading ? (
              <div ref={chartRef} className="chartBox">
                {showTable ? (
                  <div className="tableBox">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          {tableData[0].postCounts.map((value) => {
                            return <th>{value.name}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((value) => {
                          return (
                            <tr>
                              <td>{value.name}</td>
                              {value.postCounts.map((data) => {
                                return <td>{data.count}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <ReactECharts
                    echarts={echarts}
                    option={chartState.options}
                    style={isExpand ? { height: "600px", width: "100%" } : {}}
                    onEvents={{
                      click: handleSeriesClick, // Attach the click event handler
                    }}
                  />
                )}
              </div>
            ) : (
              <Shimmer />
            )}
          </div>
        </div>
        <Modal
          centered
          className="modal-lg customModel"
          show={show}
          onHide={handleClose}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="card-body">
              {!detailLoading ? (
                <ReactECharts option={detailState.options} />
              ) : (
                <Shimmer />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        {/* compare model */}
        <Modal
          centered
          className="modal-lg customModel"
          show={compareModel}
          onHide={() => {
            handleCompareModelClose();
          }}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Compare</Modal.Title>
          </Modal.Header>
          <Modal.Body className="compbody">
            <div className="compBox">
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen1 ? menuC : menuO}
                        alt=""
                        onClick={() => {
                          setIsMenuOpen1(!isMenuOpen1);
                        }}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen1 ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany1(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate1).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate1(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate1).format("yyyy-MM-DD")}
                          value={moment(compareEndDate1).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate1(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading1 ? (
                    <ReactECharts option={compareChartState1.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen ? menuC : menuO}
                        alt=""
                        onClick={toggleMenu}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany2(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate).format("yyyy-MM-DD")}
                          value={moment(compareEndDate).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading ? (
                    <ReactECharts option={compareChartState.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ByCompanyCard;
