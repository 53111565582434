import { requestWithToken } from "../helpers/helpers";

const fetchMediaByMedia = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPostTypePostMedia`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByThemeByMedia = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPostTypePostMediaType`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchVideoAnalysisCompany = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/videoDurationReport`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { fetchMediaByMedia, fetchByThemeByMedia, fetchVideoAnalysisCompany };
