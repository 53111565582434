import { requestWithToken } from "../helpers/helpers";

const fetchByTheme = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPostThemesEngagement`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByThemeDetails = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPostThemesEngagementDetails`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByVedioType = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/videoReportEngagement`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByVedioTypeDetails = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/videoReportEngagementDetails`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByMediaType = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPostTypePostEngagement`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByMediaTypeDetails = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPostTypePostEngagementDetails`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByPlatformType = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPlatformEngagement`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByPlatformTypeDetail = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPlatformEngagementDetails`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchEngagementBucket = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/engagementBucket`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchEngagementCompanyDetail = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/engagementBucketDetails`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchByTheme,
  fetchByVedioType,
  fetchByMediaType,
  fetchEngagementBucket,
  fetchEngagementCompanyDetail,
  fetchByPlatformType,
  fetchByThemeDetails,
  fetchByMediaTypeDetails,
  fetchByVedioTypeDetails,
  fetchByPlatformTypeDetail,
};
