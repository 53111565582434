import axios from "axios";

export const requestWithoutToken = async (method, url, body) => {
  let request;
  url = process.env.REACT_APP_API_ENDPOINT + url;

  switch (method) {
    case "GET":
      request = await axios.get(url);
      break;
    case "POST":
      request = await axios.post(url, body);
      break;
    default:
      break;
  }

  return request;
};

export const requestWithToken = async (method, url, body, responseType) => {
  let request;
  url = process.env.REACT_APP_API_ENDPOINT + url;

  const headers = {
    headers: {
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDQ3Y2NhMjVkZDdkZjI4NDA2NjAzMzYiLCJlbWFpbCI6ImJoYWdhdEB0ZWNoaW5kLmNvIiwicm9sZSI6MTAsInBheWxvYWQiOnt9LCJpYXQiOjE2OTA3OTE4NDAsImV4cCI6MTczMzk5MTg0MH0.uFi-VMHN-zKOoENKKX1CdKwESUaZ0CP4-uUFUammI4I`,
    },
  };
  if (responseType) {
    headers[`responseType`] = responseType;
  }
  switch (method) {
    case "GET":
      request = await axios.get(url, headers);
      break;
    case "POST":
      request = await axios.post(url, body, headers);
      break;
    case "PUT":
      request = await axios.put(url, body, headers);
      break;
    case "DELETE":
      request = await axios.delete(url, {
        data: body,
        headers: headers.headers,
      });
      break;

    default:
      break;
  }

  return request;
};

export const getImageFullURl = (url) => {
  return `${process.env.REACT_APP_S3_URL}${url}`;
};
