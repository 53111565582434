import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import ThemesByThemeCard from "../components/themeComponent/themesByTheme";
import ThemeByCompany from "../components/themeComponent/themeByCompany";
import ThemeByPlatform from "../components/themeComponent/themeByPlatform";
import ThemeEngagement from "../components/themeComponent/themeEngagement";
import AnalysisThemeByPlatform from "../components/themeComponent/analysisThemeByPlatform";
import AnalysisThemeByMedia from "../components/themeComponent/analysisThemeByMedia";

const ThemePage = ({ startDate, endDate }) => {
  return (
    <section class="content sec">
      <div class="cardRow">
        <ThemesByThemeCard startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <ThemeByCompany startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <ThemeByPlatform startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <ThemeEngagement startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <AnalysisThemeByPlatform startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <AnalysisThemeByMedia startDate={startDate} endDate={endDate} />
      </div>
      <Footer />
    </section>
  );
};

export default ThemePage;
