import { useEffect, useState } from "react";
import { fetchChanelWisePost } from "../../services/home";
import { getImageFullURl } from "../../helpers/helpers";
import { useSelector } from "react-redux";

const HeaderCard = ({ onChangeCompany, startDate, endDate }) => {
  const refreshValue = useSelector((state) => state.counter.value);
  const [tags, setTags] = useState([]);

  const getByPost = async () => {
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      startDate: startDate,
      endDate: endDate,
      uniqueType: localStorage.getItem("requestType"),
    };
    const res = await fetchChanelWisePost(payload);
    if (res.status) {
      setTags(res.data);
    }
  };

  useEffect(() => {
    getByPost();
  }, [onChangeCompany, startDate, endDate, refreshValue]);

  return (
    <>
      {tags.map((value, index) => {
        return (
          <div
            class={`customCard customTooltip ${index === 0 ? "active" : ""}`}
            title={value.name}
          >
            <div class="cardContainer">
              <div class="cardleft">
                <div class="cardId">{value.name}</div>
              </div>
              <div class="cardright">
                <h4 class="mainCardTitle">{value.totalPost}</h4>
                <ul class="mainCardFootDetail">
                  {value?.postCounts?.map((data) => {
                    return (
                      <li>
                        <img src={getImageFullURl(data?.icon)} alt="" />
                        <span>{data?.count}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default HeaderCard;
