import { requestWithToken } from "../helpers/helpers";

const fetchThemeByCompany = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/companyPostsByUniqueTypeThemes`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchThemeByPlatForm = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPlatFormPostThemes`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchThemeByEngagement = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/companyPostThemesByEngagement`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAnalysisThemePlatform = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPlatFormTimeWiseThemes`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAnalysisThemeMedia = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPostTypePostThemes`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchThemeByCompany,
  fetchThemeByPlatForm,
  fetchThemeByEngagement,
  fetchAnalysisThemePlatform,
  fetchAnalysisThemeMedia,
};
