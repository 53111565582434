import React, { useEffect, useRef, useState } from "react";
import logoIcon from "../assets/images/logo.svg";
import board from "../assets/images/ECHO.PNG";
import ComponentIcon from "../assets/images/Component.svg";
// import $ from "jquery";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "momnet/moment";
import {
  fetchClientHandler,
  fetchThemes,
  fetchTotalPostCount,
} from "../services/home";
import { useDispatch, useSelector } from "react-redux";
import { refresh } from "../redux/slice/filterRefreshSlice";
import { Spinner } from "react-bootstrap";

const HeaderMenu = ({ onDateChange }) => {
  const dispatch = useDispatch();
  const refreshValue = useSelector((state) => state.counter.value);

  const [scrollClass, setScrollClass] = useState("");
  const [filterType, setFilterType] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [companyHandler, setCompanyHandler] = useState([]);
  const [themes, setThemes] = useState([]);
  const [selectedCompanyHandler, setSelectedCompanyHandler] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [isDivVisible1, setIsDivVisible1] = useState(false);
  const [allFilter, setAllFilter] = useState(false);
  const [selectedDateOption, setSelectedDateOption] = useState(1);
  const divRef1 = useRef(null);
  const filterBtn = useRef(null);
  const divRef = useRef(null);
  const filterLable = useRef(null);
  const divRef2 = useRef(null);
  const filterLable1 = useRef(null);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("yyyy-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );

  const [totalPostLoading, setTotalPostLoading] = useState(false);
  const [totalPost, setTotalPost] = useState(0);

  const getPostCount = async () => {
    setTotalPostLoading(true);
    const payload = {
      uniqueType: localStorage.getItem("requestType"),
      startDate: startDate,
      endDate: endDate,
    };
    const res = await fetchTotalPostCount(payload);
    if (res.status) {
      setTotalPostLoading(false);
      setTotalPost(res.total);
    }
  };

  const handleScroll = () => {
    if (window.scrollY >= 20) {
      setScrollClass("fixed");
    } else {
      setScrollClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [value, onChange] = useState([
    moment(new Date())
      .subtract(30, "days")
      .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"),
    new Date(),
  ]);
  const onSelectDate = () => {
    setAllFilter(!allFilter);
    onDateChange(startDate, endDate);
    getPostCount();
  };

  const getClientHandler = async () => {
    const res = await fetchClientHandler();
    if (res.status) {
      setCompanyHandler(res.data);
    }
  };

  const getThemes = async () => {
    const res = await fetchThemes();
    if (res.status) {
      setThemes(res.data);
    }
  };

  useEffect(() => {
    getClientHandler();
    getThemes();
  }, []);

  useEffect(() => {
    getPostCount();
    setSelectedThemes(JSON.parse(localStorage.getItem("themes_filter")) ?? []);
    setSelectedCompanyHandler(
      JSON.parse(localStorage.getItem("company_filter")) ?? []
    );
  }, [refreshValue]);

  const onCompanyHandlerSelect = (value) => {
    const existCompany = selectedCompanyHandler.some(
      (data) => data.id === value.id
    );
    if (existCompany) {
      setSelectedCompanyHandler(
        selectedCompanyHandler.filter((data) => {
          return data.id !== value.id;
        })
      );
    } else {
      setSelectedCompanyHandler([...selectedCompanyHandler, value]);
    }
  };

  const onThemeSelect = (value) => {
    const existCompany = selectedThemes.some((data) => data.id === value.id);
    if (existCompany) {
      setSelectedThemes(
        selectedThemes.filter((data) => {
          return data.id !== value.id;
        })
      );
    } else {
      setSelectedThemes([...selectedThemes, value]);
    }
  };

  useEffect(() => {
    getPostCount();
    // if (localStorage.getItem("requestType")) {
    //   setFilterType(localStorage.getItem("requestType"));
    // }
    console.log(localStorage.getItem("requestType"));
    function handleClickFilter(event) {
      if (filterBtn.current && !divRef1) {
        setShowFilter(true);
      } else if (
        divRef1.current &&
        !divRef1.current.contains(event.target) &&
        !filterBtn.current.contains(event.target)
      ) {
        setShowFilter(false);
      }
    }
    function handleClose(event) {
      if (filterLable.current && !divRef) {
        setIsDivVisible(true);
      } else if (
        divRef.current &&
        !divRef.current.contains(event.target) &&
        !filterLable.current.contains(event.target)
      ) {
        setIsDivVisible(false);
      }
    }
    function handleClose1(event) {
      if (filterLable.current && !divRef) {
        setIsDivVisible1(true);
      } else if (
        divRef.current &&
        !divRef.current.contains(event.target) &&
        !filterLable1.current.contains(event.target)
      ) {
        setIsDivVisible1(false);
      }
    }

    document.addEventListener("click", handleClickFilter);
    document.addEventListener("click", handleClose);
    document.addEventListener("click", handleClose1);
    return () => {
      document.removeEventListener("click", handleClickFilter);
      document.removeEventListener("click", handleClose);
      document.removeEventListener("click", handleClose1);
    };
  }, []);

  const toggleDivClass = () => {
    setShowFilter(!showFilter);
  };

  const applyFilter = () => {
    setShowFilter(false);
    dispatch(refresh());
    localStorage.setItem(
      "company_filter",
      JSON.stringify(selectedCompanyHandler)
    );
    localStorage.setItem("themes_filter", JSON.stringify(selectedThemes));
  };

  const resetFilter = () => {
    setShowFilter(false);
    dispatch(refresh());
    localStorage.setItem("company_filter", JSON.stringify([]));
    localStorage.setItem("themes_filter", JSON.stringify([]));
  };

  const showAllFilter = () => {
    setAllFilter(!allFilter);
  };

  useEffect(() => {
    if (filterType !== localStorage.getItem("requestType")) {
      localStorage.setItem("requestType", filterType);
      dispatch(refresh());
    }
    getPostCount();
  }, [filterType]);

  return (
    <div className={`topNavbar ${scrollClass}`}>
      <ul className="topNavbarUl">
        <li className="topNavbarLi">
          <a href="#news">
            {/* <h4>Dashboard</h4> */}
            <img src={board} alt="" />
          </a>
        </li>
      </ul>
      <ul className="topNavbarUl">
        {/* <DateRangePicker
          className="dateInput topNavbarLi celender"
          value={value}
          onChange={(e) => {
            if (e) {
              onSelectDate(e);
            }
          }}
        /> */}

        <div className="dataPostBox">
          <div className="postText">Posts</div>
          <div className="dataPost">
            {!totalPostLoading ? totalPost : <Spinner />}
          </div>
        </div>
        <div className="dtfilter">
          <button className="themeBtn" onClick={showAllFilter}>
            <i className="fa fa-filter"></i> Date Filter
          </button>
          {allFilter && (
            <div className="menu-sub-dropdown">
              <div class="fttrCard">
                <div class="fttrCardHeader">Filter Options</div>
                <div class="fttrCardBody">
                  <p>Select Options</p>

                  <div class="fltrformBox">
                    <div class="fltrformrow">
                      {/* <label class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value={1}
                          checked={selectedDateOption === 1}
                          name="date_filter"
                          onChange={() => {
                            setSelectedDateOption(1);
                          }}
                        />
                        <span class="form-check-label">All </span>
                      </label> */}
                      <label class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value={2}
                          checked={selectedDateOption === 1}
                          name="date_filter"
                          onChange={() => {
                            setSelectedDateOption(1);
                            setStartDate(
                              moment(new Date())
                                .subtract(90, "days")
                                .format("yyyy-MM-DD")
                            );
                            setEndDate(moment(new Date()).format("yyyy-MM-DD"));
                          }}
                        />
                        <span class="form-check-label">Last 3 Month</span>
                      </label>
                      <label class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value={2}
                          checked={selectedDateOption === 2}
                          name="date_filter"
                          onChange={() => {
                            setSelectedDateOption(2);
                            setStartDate(
                              moment().startOf("month").format("yyyy-MM-DD")
                            );
                            setEndDate(moment(new Date()).format("yyyy-MM-DD"));
                          }}
                        />
                        <span class="form-check-label">Current Month</span>
                      </label>

                      <label class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value={3}
                          checked={selectedDateOption === 3}
                          name="date_filter"
                          onChange={() => {
                            setSelectedDateOption(3);
                            setStartDate(
                              moment(new Date())
                                .subtract(60, "days")
                                .format("yyyy-MM-DD")
                            );
                            setEndDate(
                              moment(new Date())
                                .subtract(30, "days")
                                .format("yyyy-MM-DD")
                            );
                          }}
                        />
                        <span class="form-check-label">Last Month</span>
                      </label>
                      <label class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value={4}
                          checked={selectedDateOption === 4}
                          name="date_filter"
                          onChange={() => {
                            setSelectedDateOption(4);
                            setStartDate(
                              moment(new Date())
                                .subtract(1, "days")
                                .format("yyyy-MM-DD")
                            );
                            setEndDate(
                              moment(new Date())
                                .subtract(1, "days")
                                .format("yyyy-MM-DD")
                            );
                          }}
                        />
                        <span class="form-check-label">Yesterday</span>
                      </label>

                      <label class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="today"
                          name={5}
                          checked={selectedDateOption === 5}
                          onChange={() => {
                            setSelectedDateOption(5);
                            setStartDate(
                              moment(new Date()).format("yyyy-MM-DD")
                            );
                            setEndDate(moment(new Date()).format("yyyy-MM-DD"));
                          }}
                        />
                        <span class="form-check-label">Today</span>
                      </label>
                    </div>
                  </div>
                  <p>Date Filter</p>
                  <div class="inputRow">
                    <input
                      type="date"
                      class="ipuptTheme"
                      name="from_date"
                      value={moment(startDate).format("yyyy-MM-DD")}
                      max={moment(new Date()).format("yyyy-MM-DD")}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </div>
                  <div class="inputRow">
                    <input
                      type="date"
                      class="ipuptTheme"
                      name="to_date"
                      min={moment(startDate).format("yyyy-MM-DD")}
                      value={moment(endDate).format("yyyy-MM-DD")}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="frmfoot">
                    <button
                      class="themeBtn"
                      onClick={() => {
                        onSelectDate();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <li className="topNavbarLi">
          <div className="slideBtn">
            <button
              onClick={() => {
                setFilterType(0);
              }}
              id="All"
              className={`switchBtn ${filterType === 0 ? "active" : ""}`}
            >
              All
            </button>
            <button
              onClick={() => {
                setFilterType(1);
              }}
              id="Unique"
              className={`switchBtn ${filterType === 1 ? "active" : ""}`}
            >
              Unique
            </button>
            <button
              onClick={() => {
                setFilterType(2);
              }}
              id="Unique"
              className={`switchBtn ${filterType === 2 ? "active" : ""}`}
            >
              Repeat
            </button>
          </div>
        </li>
        <li className="topNavbarLi filter">
          <a id="filterBtn">
            <img
              ref={filterBtn}
              src={ComponentIcon}
              alt=""
              onClick={toggleDivClass}
            />
          </a>
          <div
            className={`addFilterFormBox ${showFilter ? "show" : ""}`}
            ref={divRef1}
          >
            <div className="addFilter">
              <h5>Filter by</h5>
              <label for="themes" className="themeLabel">
                Client Handler
              </label>
              <div
                className="dropdown-sec"
                data-control="checkbox-dropdown"
                ref={filterLable}
              >
                <label
                  className="dropdown-label"
                  onClick={() => {
                    setIsDivVisible(!isDivVisible);
                    setIsDivVisible1(false);
                  }}
                >
                  {selectedCompanyHandler.length
                    ? `${selectedCompanyHandler.length} handler selected`
                    : "Select Handler"}
                </label>

                <div
                  className={`dropdown-list ${isDivVisible ? "show" : ""}`}
                  ref={divRef}
                >
                  {companyHandler &&
                    companyHandler.map((value) => {
                      return (
                        <label className="dropdown-option">
                          <input
                            type="checkbox"
                            name="dropdown-group"
                            checked={selectedCompanyHandler.some(
                              (data) => data.id === value._id
                            )}
                            value={value._id}
                            onChange={(e) => {
                              onCompanyHandlerSelect({
                                value: value.name,
                                id: value._id,
                              });
                            }}
                          />
                          {value.name}
                        </label>
                      );
                    })}
                </div>
              </div>
              <label for="themes" className="themeLabel">
                Themes
              </label>
              <div
                className="dropdown-sec"
                data-control="checkbox-dropdown"
                ref={filterLable1}
              >
                <label
                  className="dropdown-label"
                  onClick={() => {
                    setIsDivVisible1(!isDivVisible1);
                    setIsDivVisible(false);
                  }}
                >
                  {selectedThemes.length
                    ? `${selectedThemes.length} theme selected`
                    : " Select Themes"}
                </label>
                <div
                  className={`dropdown-list ${isDivVisible1 ? "show" : ""}`}
                  ref={divRef1}
                >
                  {themes &&
                    themes.map((value) => {
                      return (
                        <label className="dropdown-option">
                          <input
                            type="checkbox"
                            name="dropdown-group"
                            checked={selectedThemes.some(
                              (data) => data.id === value._id
                            )}
                            value={value._id}
                            onChange={(e) => {
                              onThemeSelect({
                                value: value.name,
                                id: value._id,
                              });
                            }}
                          />
                          {value.name}
                        </label>
                      );
                    })}
                </div>
              </div>

              <div className="footerBtnBox">
                <button
                  className="btn btnRoundOutline"
                  onClick={() => {
                    resetFilter();
                  }}
                >
                  Reset
                </button>
                <button
                  className="btn btnRoundTheme"
                  onClick={() => {
                    applyFilter();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default HeaderMenu;
