import React from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { decrement, increment } from "../redux/slice/counterSlice";

const Footer = () => {
  // const count = useSelector((state) => state.counter.value);
  // const dispatch = useDispatch();
  return (
    <div class="footer">
      <p>
        {" "}
        &copy;all copyrights reserved by CustomerAiZation Technologies Private
        Limited
      </p>
    </div>
  );
};

export default Footer;
