import bar from "../../assets/images/bar.svg";
import cIcon from "../../assets/images/table_icon.svg";
import fluent from "../../assets/images/info_icon.svg";
import carbonImg from "../../assets/images/image_icon.svg";
import pepicon from "../../assets/images/full_view_icon.svg";
import compare from "../../assets/images/compare_icon.svg";
import shrink from "../../assets/images/shrink.png";
import menuO from "../../assets/images/menu-o.png";
import menuC from "../../assets/images/menu-c.png";
import DropBox from "../dropBox";

import * as echarts from "echarts"; // Import ECharts library
import { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import {
  fetchByPlatFormPostLastWeek,
  fetchBylastOneMonthReport,
  fetchBylastOneMonthReportByCompany,
  fetchCompanyList,
} from "../../services/home";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Shimmer from "../shimmer";
import html2canvas from "html2canvas";
import moment from "momnet";
import {
  fetchThemeByCompany,
  fetchThemeByPlatForm,
} from "../../services/theme";

const ThemeByPlatform = ({ startDate, endDate }) => {
  const chartRef = useRef(null);
  const refreshValue = useSelector((state) => state.counter.value);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [compareModel, setCompareModel] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [themeType, setThemeType] = useState(1);
  const [themeType1, setThemeType1] = useState(1);
  const [themeType2, setThemeType2] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showTable, setShowTable] = useState(false);
  const [bigestValueIndex, setBigestValuleIndex] = useState(0);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableValue, setTableValue] = useState([]);
  const [isExpand, setIsExpand] = useState(false);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany1, setSelectedCompany1] = useState("");
  const [selectedCompany2, setSelectedCompany2] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleInfo = () => {
    setInfoVisible(!isInfoVisible);
  };
  const showExpand = () => {
    setIsExpand(!isExpand);
  };

  const [chartState, setChartState] = useState({
    options: {
      //   tooltip: {
      //     trigger: "axis",
      //     axisPointer: {
      //       // Use axis to trigger tooltip
      //       type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      //     },
      //   },

      tooltip: {
        trigger: "item",
        formatter: function (params) {
          console.log(params);
          return `
                 <b>${params.data.company}</b></br>
                 <b>${params.value}</b> : ${params.data.name} <br />`;
        },
      },

      // toolbox: {
      //   feature: {
      //     saveAsImage: {},
      //   },
      // },
      legend: {
        // Try 'horizontal'
        orient: "horizontal",
        bottom: 0,
        // top: 20,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 20, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: false,
        },
      },
      series: [],
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                console.log("X: " + this.x + ", Y: " + this.y);
                //call function passing this values as arguments
              },
            },
          },
        },
      },
    },
  });

  const [compareStartDate, setCompareStartDate] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate, setCompareEndDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading, setCompareLoading] = useState(false);
  const [compareChartState, setCompareChartState] = useState({
    options: {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `
                 <b>${params.data.company}</b></br>
                 <b>${params.value}</b> : ${params.data.name} <br />`;
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 20, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "value",
        splitLine: { show: false },
      },
      series: [
        {
          name: "Direct",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [320, 302, 301, 334, 390, 330, 320],
        },
        {
          name: "Mail Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [120, 132, 101, 134, 90, 230, 210],
        },
        {
          name: "Affiliate Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [220, 182, 191, 234, 290, 330, 310],
        },
        {
          name: "Video Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [150, 212, 201, 154, 190, 330, 410],
        },
      ],
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                console.log("X: " + this.x + ", Y: " + this.y);
                //call function passing this values as arguments
              },
            },
          },
        },
      },
    },
  });

  const [compareStartDate1, setCompareStartDate1] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate1, setCompareEndDate1] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading1, setCompareLoading1] = useState(false);
  const [compareChartState1, setCompareChartState1] = useState({
    options: {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `
                 <b>${params.data.company}</b></br>
                 <b>${params.value}</b> : ${params.data.name} <br />`;
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 20, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "value",
        splitLine: { show: false },
      },
      series: [
        {
          name: "Direct",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [320, 302, 301, 334, 390, 330, 320],
        },
        {
          name: "Mail Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [120, 132, 101, 134, 90, 230, 210],
        },
        {
          name: "Affiliate Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [220, 182, 191, 234, 290, 330, 310],
        },
        {
          name: "Video Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [150, 212, 201, 154, 190, 330, 410],
        },
      ],
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                console.log("X: " + this.x + ", Y: " + this.y);
                //call function passing this values as arguments
              },
            },
          },
        },
      },
    },
  });

  // Event handler for series click
  const handleSeriesClick = (params) => {
    if (params.seriesType === "bar") {
      handleShow();
      setTitle(params.name);
      getByCompanyDetailsPost(params.data.id);
      // Handle click event for bar series
      console.log("Clicked on bar series:", params);
      handleShow();
    }
  };

  const [loadingDetailState, setLoadingDetailState] = useState(false);
  const [detailState, setDetailState] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: [],
        },
      ],
      yAxis: [
        {
          type: "value",
          splitLine: { show: false },
        },
      ],
      series: [],
    },
  });

  const getByCompanyPost = async () => {
    setLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: startDate,
      endDate: endDate,
      themesType: themeType,
    };
    const res = await fetchThemeByPlatForm(payload);
    if (res.status) {
      setTableData(res.data);

      let monthArray = res.data.map((element) => {
        return { value: element.name, data: element._id };
      });

      /// table value data

      let indexId = 0;
      res.data.map((value, index) => {
        if (res?.data[0]?.themesData?.length < value.themesData?.length) {
          setBigestValuleIndex(index);
          indexId = index;
        }
      });

      /// table header
      let tempHeader = [];
      res.data.map((value, index) => {
        value?.themesData?.map((postValue) => {
          if (!tempHeader.includes(postValue.data)) {
            tempHeader.push(postValue.data);
          }
        });
      });
      setTableHeader(tempHeader);

      let tempTableData = [];

      res.data.map((value) => {
        let tt = [];
        [...Array(tempHeader.length).keys()].map((value) => {
          tt.push({});
        });
        tempHeader?.map((themeValue, index) => {
          value?.themesData?.map((thmValue, thmIndex) => {
            if (themeValue == thmValue.data) {
              tt.splice(
                tempHeader.findIndex((x) => x === thmValue.data),
                1,
                thmValue
              );
            }
          });
        });

        tempTableData.push({ name: value.name, themesData: tt });
      });

      setTableValue(tempTableData);

      console.log("JDIOFJ", tempTableData);

      let companyLength = res.data.length;
      const tempArray = [];
      const valueArray = [];

      for (let k = 0; k < 100; k++) {
        let tempArray = [];
        res.data.map((mainObject) => {
          if (mainObject.themesData) {
            tempArray.push(
              {
                value: mainObject?.themesData[k]?.count ?? 0,
                name: mainObject?.themesData[k]?.data ?? "",
                company: mainObject?.name,
              } ?? ""
            );
          }
        });
        valueArray.push({
          name: "",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: tempArray,
        });
      }

      chartState.options.xAxis.data = monthArray;
      chartState.options.series = valueArray;

      setChartState(chartState);
      setLoading(true);
    }
  };

  const getByCompanyDetailsPost = async (id) => {
    setLoadingDetailState(true);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: startDate,
      endDate: endDate,
    };
    const res = await fetchBylastOneMonthReportByCompany(id, payload);
    if (res.status) {
      let months = res.data.map((value) => {
        return value.monthName;
      });

      const dataLength = res.data.length;
      const postCountLength = res.data[0].channelDetails.length;
      const tempArray = [];
      const valueArray = [];

      for (let i = 0; i < postCountLength; i++) {
        tempArray.push(
          res.data.map((value, index) => {
            return value.channelDetails[i];
          })
        );
      }

      tempArray.map((value) => {
        console.log(value);
        valueArray.push({
          name: value[0].name,
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        });
      });

      for (let j = 0; j < tempArray.length; j++) {
        for (let i = 0; i < dataLength; i++) {
          valueArray[j].data[i] = tempArray[j][i].count;
        }
      }

      detailState.options.xAxis[0].data = months;
      detailState.options.series = valueArray;
      setDetailState(detailState);
      setLoadingDetailState(false);
      console.log("JIOFJD", months);
    }
  };

  const getCompareByCompanyPost1 = async () => {
    setCompareLoading1(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate1,
      endDate: compareEndDate1,
      themesType: Number(themeType1),
    };
    if (selectedCompany1) {
      payload.companyIds = [selectedCompany1];
    }
    const res = await fetchThemeByPlatForm(payload);
    if (res.status) {
      // setTableData(res.data);
      let monthArray = res.data.map((element) => {
        return { value: element.name, data: element._id };
      });

      // res.data.map((value, index) => {
      //   if (res.data[0].themesData?.length < value.themesData?.length) {
      //     setBigestValuleIndex(index);
      //   }
      // });

      let companyLength = res.data.length;
      const tempArray = [];
      const valueArray = [];

      for (let k = 0; k < companyLength; k++) {
        let tempArray = [];
        res.data.map((mainObject) => {
          if (mainObject.themesData) {
            tempArray.push(
              {
                value: mainObject?.themesData[k]?.count ?? 0,
                name: mainObject?.themesData[k]?.data ?? "",
                company: mainObject?.name,
              } ?? ""
            );
          }
        });
        valueArray.push({
          name: "",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: tempArray,
        });
      }

      compareChartState1.options.xAxis.data = monthArray;
      compareChartState1.options.series = valueArray;

      setCompareChartState1(compareChartState1);
      setCompareLoading1(true);
    }
  };

  const getCompareByCompanyPost = async () => {
    setCompareLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate,
      endDate: compareEndDate,
      themesType: themeType2,
    };
    if (selectedCompany2) {
      payload.companyIds = [selectedCompany2];
    }
    const res = await fetchThemeByPlatForm(payload);
    if (res.status) {
      // setTableData(res.data);
      let monthArray = res.data.map((element) => {
        return { value: element.name, data: element._id };
      });

      // res.data.map((value, index) => {
      //   if (res.data[0].themesData?.length < value.themesData?.length) {
      //     setBigestValuleIndex(index);
      //   }
      // });

      let companyLength = res.data.length;
      const tempArray = [];
      const valueArray = [];

      for (let k = 0; k < companyLength; k++) {
        let tempArray = [];
        res.data.map((mainObject) => {
          if (mainObject.themesData) {
            tempArray.push(
              {
                value: mainObject?.themesData[k]?.count ?? 0,
                name: mainObject?.themesData[k]?.data ?? "",
                company: mainObject?.name,
              } ?? ""
            );
          }
        });
        valueArray.push({
          name: "",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: tempArray,
        });
      }

      compareChartState.options.xAxis.data = monthArray;
      compareChartState.options.series = valueArray;

      setCompareChartState(compareChartState);
      setCompareLoading(true);
    }
  };

  const saveAsImage = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
      });
    }
  };

  const getCompanyList = async () => {
    const res = await fetchCompanyList();
    if (res.status) {
      setCompanyList(res.data);
    }
  };

  useEffect(() => {
    getByCompanyPost();
  }, [startDate, endDate, refresh, refreshValue, themeType]);

  useEffect(() => {
    getCompareByCompanyPost();
  }, [compareEndDate, compareStartDate, selectedCompany2, themeType2]);

  useEffect(() => {
    getCompareByCompanyPost1();
  }, [compareEndDate1, compareStartDate1, selectedCompany1, themeType1]);

  useEffect(() => {
    getCompanyList();
  }, []);

  const handleCompareModelClose = () => {
    setCompareStartDate1(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate1(moment(new Date()).format("yyyy-MM-DD"));
    setCompareStartDate(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate(moment(new Date()).format("yyyy-MM-DD"));
    setSelectedCompany1("");
    setSelectedCompany2("");
    setCompareModel(false);
  };

  return (
    // <p>Loading...</p>
    <>
      {isExpand && <DropBox />}

      <div class={`themeCard ${isExpand ? "expand" : ""}`}>
        <div class="CardContainer">
          <header class="cardHeader">
            <h5>Theme By Platform</h5>
            <div class="headerRight">
              <ul class="headerRightItems">
                <li
                  class={
                    themeType == 1
                      ? "theme-btn t-theme active"
                      : "theme-btn t-theme"
                  }
                  onClick={() => {
                    setThemeType(1);
                  }}
                >
                  Theme
                </li>
                <li
                  class={themeType == 2 ? "theme-btn  active" : "theme-btn"}
                  onClick={() => {
                    setThemeType(2);
                  }}
                >
                  Sub Theme
                </li>
                <li
                  class={
                    themeType == 3
                      ? "theme-btn sstheme active"
                      : "theme-btn sstheme"
                  }
                  onClick={() => {
                    setThemeType(3);
                  }}
                >
                  Sub Sub Theme
                </li>
              </ul>
              <ul class="headerRightItems">
                <li onClick={setRefresh}>
                  {" "}
                  <a>
                    <img src={bar} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    setShowTable(!showTable);
                  }}
                >
                  {" "}
                  <a>
                    <img src={cIcon} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    getCompareByCompanyPost();
                    setCompareModel(true);
                  }}
                >
                  <a>
                    <img src={compare} alt="" />
                  </a>
                </li>
                <li onClick={toggleInfo}>
                  {" "}
                  <a>
                    <img src={fluent} alt="" />
                  </a>
                  {isInfoVisible && (
                    <div className="chartInfo">
                      <div className="infottl">Information</div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                    </div>
                  )}
                </li>
                <li
                  onClick={() => {
                    saveAsImage();
                  }}
                >
                  {" "}
                  <a>
                    <img src={carbonImg} alt="" />
                  </a>
                </li>

                {/* <li onClick={setRefresh}>
                {" "}
                <a>
                  <img src={carbonImg} alt="" />
                </a>
              </li> */}
                <li
                  className={`${isExpand ? "active" : ""}`}
                  onClick={showExpand}
                >
                  {" "}
                  <a>
                    <img src={isExpand ? shrink : pepicon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </header>
          <div class="card-body">
            {/* <ReactECharts option={chartState.options} /> */}
            {loading ? (
              <div ref={chartRef} className="chartBox">
                {showTable ? (
                  <div className="tableBox">
                    <table>
                      <thead>
                        <tr>
                          <th>Named</th>
                          {tableHeader?.map((value) => {
                            return <th>{value}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tableValue?.map((value) => {
                          return (
                            <tr>
                              <td>{value?.name}</td>
                              {value?.themesData.map((value) => {
                                return <td>{value?.count ?? 0}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <ReactECharts
                    echarts={echarts}
                    option={chartState.options}
                    style={isExpand ? { height: "600px", width: "100%" } : {}}
                    onEvents={{
                      click: handleSeriesClick, // Attach the click event handler
                    }}
                  />
                )}
              </div>
            ) : (
              <Shimmer />
            )}
          </div>
        </div>
        <Modal
          centered
          show={show}
          onHide={handleClose}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
          className="modal-lg customModel"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="card-body">
              {!loadingDetailState ? (
                <ReactECharts option={detailState.options} />
              ) : (
                <Shimmer />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        {/* compare model */}
        <Modal
          centered
          show={compareModel}
          onHide={() => {
            handleCompareModelClose();
          }}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
          className="modal-lg customModel1"
        >
          <Modal.Header closeButton>
            <Modal.Title>Compare</Modal.Title>
          </Modal.Header>
          <Modal.Body className="compbody">
            <div className="compBox">
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen1 ? menuC : menuO}
                        alt=""
                        onClick={() => {
                          setIsMenuOpen1(!isMenuOpen1);
                        }}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen1 ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany1(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          value={themeType1}
                          onChange={(e) => {
                            setThemeType1(e.target.value);
                          }}
                        >
                          {/* <option>select theme type</option> */}
                          <option value={1}>Theme</option>
                          <option value={2}>Sub Theme</option>
                          <option value={3}>Sub sub Theme</option>
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate1).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate1(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate1).format("yyyy-MM-DD")}
                          value={moment(compareEndDate1).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate1(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading1 ? (
                    <ReactECharts option={compareChartState1.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen ? menuC : menuO}
                        alt=""
                        onClick={toggleMenu}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany2(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          value={themeType2}
                          onChange={(e) => {
                            setThemeType2(e.target.value);
                          }}
                        >
                          {/* <option>select theme type</option> */}
                          <option value={1}>Theme</option>
                          <option value={2}>Sub Theme</option>
                          <option value={3}>Sub sub Theme</option>
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate).format("yyyy-MM-DD")}
                          value={moment(compareEndDate).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading ? (
                    <ReactECharts option={compareChartState.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ThemeByPlatform;
