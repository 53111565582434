import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slice/filterRefreshSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
  },
});

/// for example use this link ==> https://redux-toolkit.js.org/tutorials/quick-start
