const Login = () => {
  return (
    <div className="corplogInPage">
      <div className="crpLoginPage">
        <div className="crpImage">
          <img src="/corpBird.png" alt="" />
        </div>
        <div className="crpLogin">
          <h3>Log in</h3>

          <div className="userlbl eror">User Id (Email Id)</div>
          <div className="loginformGroup">
            <input className="logingmailInput" placeholder="Enter User Id" type="email"  value="nagaart@gmail.com" />
            <p>Enter correct email</p>
          </div>
          <div className="passlbl">
            <p>Password</p> <a href="">Forgot password</a>
          </div>
          <div className="loginformGroup passwordInputbox">
            <input className="logingmailInput" type="password" name="password" id="password" value="nagaart@gmail.com"/>
            <div className="passwordvissiblityicon">
              <img
                src="/passwordvisibilityicon.png"
                className="eyepass"
                id="togglePassword"
              ></img>
            </div>
          </div>
          <div className="loginCheckbox">
            <input type="checkbox" />
            <p>Remember me</p>
          </div>
          <button className="crplogbtn"> Log in</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
