import c2Icon from "../../assets/images/c2.png";
import cIcon from "../../assets/images/table_icon.svg";
import fluent from "../../assets/images/info_icon.svg";
import carbonImg from "../../assets/images/image_icon.svg";
import pepicon from "../../assets/images/full_view_icon.svg";
import compare from "../../assets/images/compare_icon.svg";
import shrink from "../../assets/images/shrink.png";
import menuO from "../../assets/images/menu-o.png";
import menuC from "../../assets/images/menu-c.png";
import DropBox from "../dropBox";

import { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { fetchCompanyList, fetchThemesReport } from "../../services/home";
import html2canvas from "html2canvas";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Shimmer from "../shimmer";
import moment from "momnet";

const ThemesByThemeCard = ({ startDate, endDate }) => {
  const chartRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const refreshValue = useSelector((state) => state.counter.value);
  const [refresh, setRefresh] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [compareModel, setCompareModel] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany1, setSelectedCompany1] = useState("");
  const [selectedCompany2, setSelectedCompany2] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleInfo = () => {
    setInfoVisible(!isInfoVisible);
  };
  const showExpand = () => {
    setIsExpand(!isExpand);
  };

  const [compareStartDate1, setCompareStartDate1] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate1, setCompareEndDate1] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading1, setCompareLoading1] = useState(false);
  const [compareChartState1, setCompareChartState1] = useState({
    options: {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
      },
      series: [
        {
          type: "sankey",
          data: [],
          links: [],
          emphasis: {
            focus: "adjacency",
          },
          levels: [
            {
              depth: 0,
              itemStyle: {
                color: "#fbb4ae",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
            {
              depth: 1,
              itemStyle: {
                color: "#b3cde3",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
            {
              depth: 2,
              itemStyle: {
                color: "#ccebc5",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
            {
              depth: 3,
              itemStyle: {
                color: "#decbe4",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
          ],
          lineStyle: {
            curveness: 0.5,
          },
        },
      ],
    },
  });

  const [compareStartDate, setCompareStartDate] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate, setCompareEndDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading, setCompareLoading] = useState(false);
  const [compareChartState, setCompareChartState] = useState({
    options: {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
      },
      series: [
        {
          type: "sankey",
          data: [],
          links: [],
          emphasis: {
            focus: "adjacency",
          },
          levels: [
            {
              depth: 0,
              itemStyle: {
                color: "#fbb4ae",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
            {
              depth: 1,
              itemStyle: {
                color: "#b3cde3",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
            {
              depth: 2,
              itemStyle: {
                color: "#ccebc5",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
            {
              depth: 3,
              itemStyle: {
                color: "#decbe4",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
          ],
          lineStyle: {
            curveness: 0.5,
          },
        },
      ],
    },
  });

  const [chartState, setChartState] = useState({
    options: {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      series: [
        {
          type: "sankey",
          data: [],
          links: [],
          emphasis: {
            focus: "adjacency",
          },
          levels: [
            {
              depth: 0,
              itemStyle: {
                color: "#fbb4ae",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
            {
              depth: 1,
              itemStyle: {
                color: "#b3cde3",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
            {
              depth: 2,
              itemStyle: {
                color: "#ccebc5",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
            {
              depth: 3,
              itemStyle: {
                color: "#decbe4",
              },
              lineStyle: {
                color: "source",
                opacity: 0.6,
              },
            },
          ],
          lineStyle: {
            curveness: 0.5,
          },
        },
      ],
    },
  });

  const saveAsImage = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
      });
    }
  };

  const getByThemesReport = async () => {
    setLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: startDate,
      endDate: endDate,
    };
    const res = await fetchThemesReport(payload);
    if (res.status) {
      setTableData(res.themeData);
      let nodeLabel = [];
      nodeLabel.push({ name: "Total" });
      res?.themeData?.map((value) => {
        if (value.childpost) {
          value.childpost.map((data) => {
            nodeLabel.push({ name: data.data });
            if (data.subchildpost) {
              data.subchildpost.map((subTheme) => {
                nodeLabel.push({ name: subTheme.data });
              });
            }
          });
        }
        nodeLabel.push({ name: value.data });
      });

      let links = [];
      res?.themeData?.map((data) => {
        links.push({
          source: "Total",
          target: data.data,
          value: data.count,
        });
      });
      res?.themeData?.map((data) => {
        if (data.childpost && data.childpost.length) {
          data.childpost.map((subData) => {
            links.push({
              source: data.data,
              target: subData.data,
              value: subData.count,
            });
            if (subData.subchildpost) {
              subData.subchildpost.map((subSubTheme) => {
                links.push({
                  source: subData.data,
                  target: subSubTheme.data,
                  value: subSubTheme.count,
                });
              });
            }
          });
        }
      });

      chartState.options.series[0].data = nodeLabel;
      chartState.options.series[0].links = links;
      setChartState(chartState);
      //   {
      //     count: 3,
      //     data: "Special Days",
      //     themesId: "645320eec8f132e2b8609661",
      //     childpost: [
      //       {
      //         count: 2,
      //         data: "Occasion",
      //         themesId: "64532104c8f132e2b8609664",
      //         subchildpost: [
      //           {
      //             count: 1,
      //             data: "Independence Day",
      //             themesId: "6453210ec8f132e2b8609667",
      //           },
      //           {
      //             count: 1,
      //             data: "New Year",
      //             themesId: "6453210ec8f132e2b8609667",
      //           },
      //         ],
      //       },
      //       {
      //         count: 1,
      //         data: "Festival",
      //         themesId: "64532104c8f132e2b8609664",
      //         subchildpost: [
      //           {
      //             count: 1,
      //             data: "Diwali",
      //             themesId: "6453210ec8f132e2b8609667",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     count: 1,
      //     data: "Work Place Culture & Environment",
      //     themesId: "645320eec8f132e2b8609661",
      //     childpost: [
      //       {
      //         count: 1,
      //         data: "Company Culture",
      //         themesId: "64532104c8f132e2b8609664",
      //         subchildpost: [
      //           {
      //             count: 1,
      //             data: "ICYMI",
      //             themesId: "6453210ec8f132e2b8609667",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ];

      // chartState.options.series[0].data = nodeLabel;
      // chartState.options.series[0].links = links;
      // setChartState(chartState);
      setLoading(true);
    } else {
      setLoading(true);
    }
  };

  const getCompareByThemesReport1 = async () => {
    setCompareLoading1(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate1,
      endDate: compareEndDate1,
    };
    if (selectedCompany1) {
      payload.companyIds = [selectedCompany1];
    }
    const res = await fetchThemesReport(payload);
    if (res.status) {
      let nodeLabel = [];
      nodeLabel.push({ name: "Total" });
      res?.themeData?.map((value) => {
        if (value.childpost) {
          value.childpost.map((data) => {
            nodeLabel.push({ name: data.data });
            if (data.subchildpost) {
              data.subchildpost.map((subTheme) => {
                nodeLabel.push({ name: subTheme.data });
              });
            }
          });
        }
        nodeLabel.push({ name: value.data });
      });

      let links = [];
      res?.themeData?.map((data) => {
        links.push({
          source: "Total",
          target: data.data,
          value: data.count,
        });
      });
      res?.themeData?.map((data) => {
        if (data.childpost && data.childpost.length) {
          data.childpost.map((subData) => {
            links.push({
              source: data.data,
              target: subData.data,
              value: subData.count,
            });
            if (subData.subchildpost) {
              subData.subchildpost.map((subSubTheme) => {
                links.push({
                  source: subData.data,
                  target: subSubTheme.data,
                  value: subSubTheme.count,
                });
              });
            }
          });
        }
      });

      compareChartState1.options.series[0].data = nodeLabel;
      compareChartState1.options.series[0].links = links;
      setCompareChartState1(compareChartState1);
      setCompareLoading1(true);
    } else {
      setCompareLoading1(true);
    }
  };

  const getCompareByThemesReport = async () => {
    setCompareLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate,
      endDate: compareEndDate,
    };
    if (selectedCompany2) {
      payload.companyIds = [selectedCompany2];
    }
    const res = await fetchThemesReport(payload);
    if (res.status) {
      let nodeLabel = [];
      nodeLabel.push({ name: "Total" });
      res?.themeData?.map((value) => {
        if (value.childpost) {
          value.childpost.map((data) => {
            nodeLabel.push({ name: data.data });
            if (data.subchildpost) {
              data.subchildpost.map((subTheme) => {
                nodeLabel.push({ name: subTheme.data });
              });
            }
          });
        }
        nodeLabel.push({ name: value.data });
      });

      let links = [];
      res?.themeData?.map((data) => {
        links.push({
          source: "Total",
          target: data.data,
          value: data.count,
        });
      });
      res?.themeData?.map((data) => {
        if (data.childpost && data.childpost.length) {
          data.childpost.map((subData) => {
            links.push({
              source: data.data,
              target: subData.data,
              value: subData.count,
            });
            if (subData.subchildpost) {
              subData.subchildpost.map((subSubTheme) => {
                links.push({
                  source: subData.data,
                  target: subSubTheme.data,
                  value: subSubTheme.count,
                });
              });
            }
          });
        }
      });

      compareChartState.options.series[0].data = nodeLabel;
      compareChartState.options.series[0].links = links;
      setCompareChartState(compareChartState);
      setCompareLoading(true);
    } else {
      setCompareLoading(true);
    }
  };

  const getCompanyList = async () => {
    const res = await fetchCompanyList();
    if (res.status) {
      setCompanyList(res.data);
    }
  };

  useEffect(() => {
    getByThemesReport();
  }, [startDate, endDate, refresh, refreshValue]);

  useEffect(() => {
    getCompareByThemesReport1();
  }, [compareEndDate1, compareStartDate1, selectedCompany1]);

  useEffect(() => {
    getCompareByThemesReport();
  }, [compareEndDate, compareStartDate, selectedCompany2]);

  useEffect(() => {
    getCompanyList();
  }, []);

  return (
    <>
      {isExpand && <DropBox />}

      <div class={`themeCard ${isExpand ? "expand" : ""}`}>
        <div class="CardContainer">
          <header class="cardHeader">
            <h5>By Themes</h5>
            <div class="headerRight">
              <ul class="headerRightItems">
                <li onClick={setRefresh}>
                  {" "}
                  <a>
                    <img src={c2Icon} alt="" />
                  </a>
                </li>
                {/* <li>
                  {" "}
                  <a>
                    <img src={cIcon} alt="" />
                  </a>
                </li> */}
                <li>
                  {" "}
                  <a
                    onClick={() => {
                      setCompareModel(true);
                    }}
                  >
                    <img src={compare} alt="" />
                  </a>
                </li>
                <li onClick={toggleInfo}>
                  {" "}
                  <a>
                    <img src={fluent} alt="" />
                  </a>
                  {isInfoVisible && (
                    <div className="chartInfo">
                      <div className="infottl">Information</div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                    </div>
                  )}
                </li>
                <li
                  onClick={() => {
                    saveAsImage();
                  }}
                >
                  {" "}
                  <a>
                    <img src={carbonImg} alt="" />
                  </a>
                </li>
                <li
                  className={`${isExpand ? "active" : ""}`}
                  onClick={showExpand}
                >
                  {" "}
                  <a>
                    <img src={isExpand ? shrink : pepicon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </header>
          {loading ? (
            <div ref={chartRef} className="chartBox">
              {showTable ? (
                <div className="tableBox">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Count</th>
                        <th>Sub Theme</th>
                        <th>Sub Sub Theme</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((value) => {
                        return (
                          <tr>
                            {/* <td>{value._id}</td>
                        <td>{value.unique}</td>
                        <td>{value.repeated}</td>
                        <td>{value.totalPost}</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <ReactECharts
                  echarts={echarts}
                  style={
                    isExpand
                      ? { height: "600px", width: "100%" }
                      : { height: "400px" }
                  }
                  option={chartState.options}
                />
              )}
            </div>
          ) : (
            <Shimmer />
          )}
        </div>
        {/* compare model */}
        <Modal
          centered
          show={compareModel}
          onHide={() => {
            setCompareModel(false);
          }}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
          className="modal-lg customModel"
        >
          <Modal.Header closeButton>
            <Modal.Title>Compare</Modal.Title>
          </Modal.Header>
          <Modal.Body className="compbody">
            <div className="compBox">
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen1 ? menuC : menuO}
                        alt=""
                        onClick={() => {
                          setIsMenuOpen1(!isMenuOpen1);
                        }}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen1 ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany1(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate1).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate1(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate1).format("yyyy-MM-DD")}
                          value={moment(compareEndDate1).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate1(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading1 ? (
                    <ReactECharts option={compareChartState1.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen ? menuC : menuO}
                        alt=""
                        onClick={toggleMenu}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany2(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate).format("yyyy-MM-DD")}
                          value={moment(compareEndDate).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading ? (
                    <ReactECharts option={compareChartState.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ThemesByThemeCard;
