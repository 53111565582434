import bar from "../../assets/images/bar.svg";
import cIcon from "../../assets/images/table_icon.svg";
import fluent from "../../assets/images/info_icon.svg";
import carbonImg from "../../assets/images/image_icon.svg";
import pepicon from "../../assets/images/full_view_icon.svg";
import compare from "../../assets/images/compare_icon.svg";
import shrink from "../../assets/images/shrink.png";
import menuO from "../../assets/images/menu-o.png";
import menuC from "../../assets/images/menu-c.png";
import DropBox from "../dropBox";

import * as echarts from "echarts"; // Import ECharts library
import { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import Shimmer from "../shimmer";
import html2canvas from "html2canvas";
import moment from "momnet";
import { fetchThemeByCompany } from "../../services/theme";
import { fetchMediaByMedia } from "../../services/media";
import { fetchCompanyList } from "../../services/home";
import { Modal } from "react-bootstrap";

const ByMediaAndByVideo = ({ startDate, endDate }) => {
  const chartRef = useRef(null);
  const refreshValue = useSelector((state) => state.counter.value);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [viewType, setViewType] = useState(1);
  const [themeType, setThemeType] = useState(1);
  const [themeType1, setThemeType1] = useState(1);
  const [themeType2, setThemeType2] = useState(1);
  const [isExpand, setIsExpand] = useState(false);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany1, setSelectedCompany1] = useState("");
  const [selectedCompany2, setSelectedCompany2] = useState("");
  const [compareModel, setCompareModel] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableValue, setTableValue] = useState([]);
  const [bigestValueIndex, setBigestValuleIndex] = useState(0);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleInfo = () => {
    setInfoVisible(!isInfoVisible);
  };
  const showExpand = () => {
    setIsExpand(!isExpand);
  };

  const [chartState, setChartState] = useState({
    options: {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          console.log(params);
          return `
                 <b>${params.data.company}</b></br>
                 <b>${params.value}</b> : ${params.data.name} <br />`;
        },
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {},
      //   },
      // },
      legend: {
        // Try 'horizontal'
        orient: "horizontal",
        bottom: 0,
        // top: 20,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 20, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: "Direct",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Mail Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Affiliate Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Video Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                console.log("X: " + this.x + ", Y: " + this.y);
                //call function passing this values as arguments
              },
            },
          },
        },
      },
    },
  });

  const [compareStartDate1, setCompareStartDate1] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate1, setCompareEndDate1] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading1, setCompareLoading1] = useState(false);
  const [compareChartState1, setCompareChartState1] = useState({
    options: {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          console.log(params);
          return `
                 <b>${params.data.company}</b></br>
                 <b>${params.value}</b> : ${params.data.name} <br />`;
        },
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {},
      //   },
      // },
      legend: {
        // Try 'horizontal'
        orient: "horizontal",
        bottom: 0,
        // top: 20,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 20, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: "Direct",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Mail Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Affiliate Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Video Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                console.log("X: " + this.x + ", Y: " + this.y);
                //call function passing this values as arguments
              },
            },
          },
        },
      },
    },
  });

  // console.log("tData ", tableData)

  const [compareStartDate, setCompareStartDate] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate, setCompareEndDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading, setCompareLoading] = useState(false);
  const [compareChartState, setCompareChartState] = useState({
    options: {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          console.log(params);
          return `
                 <b>${params.data.company}</b></br>
                 <b>${params.value}</b> : ${params.data.name} <br />`;
        },
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {},
      //   },
      // },
      legend: {
        // Try 'horizontal'
        orient: "horizontal",
        bottom: 0,
        // top: 20,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 20, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: "Direct",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Mail Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Affiliate Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Video Ad",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                console.log("X: " + this.x + ", Y: " + this.y);
                //call function passing this values as arguments
              },
            },
          },
        },
      },
    },
  });

  const getByMedia = async () => {
    setLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: startDate,
      endDate: endDate,
      type: themeType,
    };
    if (viewType == 2) {
      payload.mediaType = 2;
    }
    const res = await fetchMediaByMedia(payload);
    if (res.status) {
      setTableData(res.data);
      if (viewType == 1) {
        let indexId = 0;
        res.data.map((value, index) => {
          if (res.data[0].data?.length <= value.data?.length) {
            // console.log("cIndex  ", index)
            setBigestValuleIndex(index);
            indexId = index;
          }
        });
        let tempTableData = [];
        res.data.map((value) => {
          let tt = [];
          [...Array(res.data[indexId]?.data?.length).keys()].map((value) => {
            tt.push({});
          });
          res.data[indexId].data.map((themeValue, index) => {
            value.data.map((thmValue, thmIndex) => {
              if (themeValue.name == thmValue.name) {
                tt.splice(
                  res.data[indexId].data.findIndex(
                    (x) => x.name === thmValue.name
                  ),
                  1,
                  thmValue
                );
              }
            });
          });

          tempTableData.push({
            name: themeType == 1 ? value._id : value.name,
            data: tt,
          });
        });
        // console.log("JDOIFJ", tempTableData);
        // console.log("index", indexId);

        setTableValue(tempTableData);

        let labels = res.data.map((value) => {
          return themeType == 1 ? value?._id : value?.name;
        });
        let companyLength = res.data.length;
        const tempArray = [];
        const valueArray = [];

        for (let k = 0; k < 100; k++) {
          let tempArray = [];
          res.data.map((mainObject) => {
            // console.log(mainObject.data[k]?.count, "count");
            tempArray.push(
              {
                value: mainObject.data[k]?.count ?? 0,
                name: mainObject.data[k]?.name ?? "",
                company: themeType == 1 ? mainObject._id : mainObject.name,
              } ?? ""
            );
          });
          valueArray.push({
            name: "",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: tempArray,
          });
        }

        chartState.options.xAxis.data = labels;
        chartState.options.series = valueArray;
      } else {
        let indexId = 0;

        const newData = [];

        res.data.forEach((item) => {
          for (const rangeKey in item) {
            const range = item[rangeKey];
            newData.push(range);
          }
        });

        setTableData(newData);

        newData.map((value, index) => {
          if (newData[0].data?.length <= value.data?.length) {
            setBigestValuleIndex(index);
            indexId = index;
          }
        });
        let tempTableData = [];
        newData.map((value) => {
          let tt = [];
          [...Array(newData[indexId]?.data?.length).keys()].map((value) => {
            tt.push({});
          });
          newData[indexId].data.map((themeValue, index) => {
            value.data.map((thmValue, thmIndex) => {
              if (themeValue._id == thmValue._id) {
                tt.splice(
                  newData[indexId].data.findIndex(
                    (x) => x._id === thmValue._id
                  ),
                  1,
                  thmValue
                );
              }
            });
          });

          tempTableData.push({ name: value.range, data: tt });
        });
        setTableValue(tempTableData);

        // console.log("data ", tableData)

        let resData = Object.values(res.data[0]);

        let labels = resData.map((value) => {
          return value.range;
        });

        let companyLength = resData.length;
        const tempArray = [];
        const valueArray = [];

        for (let k = 0; k < 100; k++) {
          let tempArray = [];
          resData.map((mainObject) => {
            // console.log(mainObject.data[k]?.count, "count");
            tempArray.push(
              {
                value: mainObject.data[k]?.count ?? 0,
                name:
                  themeType == 1
                    ? mainObject.data[k]?._id
                    : mainObject.data[k]?.name,
                company: mainObject.range,
              } ?? ""
            );
          });
          valueArray.push({
            name: "",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: tempArray,
          });
        }

        chartState.options.xAxis.data = labels;
        chartState.options.series = valueArray;
      }
      setChartState(chartState);
      setLoading(true);
    }
  };

  const getCompareByMedia1 = async () => {
    setCompareLoading1(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate1,
      endDate: compareEndDate1,
      type: themeType1,
    };
    if (viewType == 2) {
      payload.mediaType = 2;
    }
    if (selectedCompany1) {
      payload.companyIds = [selectedCompany1];
    }
    const res = await fetchMediaByMedia(payload);
    if (res.status) {
      if (viewType == 1) {
        let labels = res.data.map((value) => {
          return themeType1 == 1 ? value._id : value.name;
        });
        let companyLength = res.data.length;
        const tempArray = [];
        const valueArray = [];

        for (let k = 0; k < 100; k++) {
          let tempArray = [];
          res.data.map((mainObject) => {
            // console.log(mainObject.data[k]?.count, "count");
            tempArray.push(
              {
                value: mainObject.data[k]?.count ?? 0,
                name: mainObject.data[k]?.name ?? "",
                company: themeType1 == 1 ? mainObject._id : mainObject.name,
              } ?? ""
            );
          });
          valueArray.push({
            name: "",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: tempArray,
          });
        }

        compareChartState1.options.xAxis.data = labels;
        compareChartState1.options.series = valueArray;
      } else {
        let resData = Object.values(res.data[0]);

        let labels = resData.map((value) => {
          return value.range;
        });

        let companyLength = resData.length;
        const tempArray = [];
        const valueArray = [];

        for (let k = 0; k < 100; k++) {
          let tempArray = [];
          resData.map((mainObject) => {
            // console.log(mainObject.data[k]?.count, "count");
            tempArray.push(
              {
                value: mainObject.data[k]?.count ?? 0,
                name:
                  themeType1 == 1
                    ? mainObject.data[k]?._id
                    : mainObject.data[k]?.name,
                company: mainObject.range,
              } ?? ""
            );
          });
          valueArray.push({
            name: "",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: tempArray,
          });
        }

        compareChartState1.options.xAxis.data = labels;
        compareChartState1.options.series = valueArray;
      }

      setCompareChartState1(compareChartState1);
      setCompareLoading1(true);
    }
  };

  const getCompareByMedia = async () => {
    setCompareLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate,
      endDate: compareEndDate,
      type: themeType2,
    };
    if (viewType == 2) {
      payload.mediaType = 2;
    }
    if (selectedCompany2) {
      payload.companyIds = [selectedCompany2];
    }
    const res = await fetchMediaByMedia(payload);
    if (res.status) {
      if (viewType == 1) {
        let labels = res.data.map((value) => {
          return themeType2 == 1 ? value._id : value.name;
        });
        let companyLength = res.data.length;
        const tempArray = [];
        const valueArray = [];

        for (let k = 0; k < 100; k++) {
          let tempArray = [];
          res.data.map((mainObject) => {
            // console.log(mainObject.data[k]?.count, "count");
            tempArray.push(
              {
                value: mainObject.data[k]?.count ?? 0,
                name: mainObject.data[k]?.name ?? "",
                company: themeType == 2 ? mainObject._id : mainObject.name,
              } ?? ""
            );
          });
          valueArray.push({
            name: "",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: tempArray,
          });
        }

        compareChartState.options.xAxis.data = labels;
        compareChartState.options.series = valueArray;
      } else {
        let resData = Object.values(res.data[0]);

        let labels = resData.map((value) => {
          return value.range;
        });

        let companyLength = resData.length;
        const tempArray = [];
        const valueArray = [];

        for (let k = 0; k < 100; k++) {
          let tempArray = [];
          resData.map((mainObject) => {
            // console.log(mainObject.data[k]?.count, "count");
            tempArray.push(
              {
                value: mainObject.data[k]?.count ?? 0,
                name:
                  themeType2 == 1
                    ? mainObject.data[k]?._id
                    : mainObject.data[k]?.name,
                company: mainObject.range,
              } ?? ""
            );
          });
          valueArray.push({
            name: "",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: tempArray,
          });
        }

        compareChartState.options.xAxis.data = labels;
        compareChartState.options.series = valueArray;
      }

      setCompareChartState(compareChartState);
      setCompareLoading(true);
    }
  };

  useEffect(() => {
    getByMedia();
  }, [startDate, endDate, refresh, refreshValue, themeType]);
  useEffect(() => {
    getCompareByMedia();
  }, [compareEndDate, compareStartDate, selectedCompany2, themeType2]);

  useEffect(() => {
    getCompareByMedia1();
  }, [compareEndDate1, compareStartDate1, selectedCompany1, themeType1]);

  useEffect(() => {
    getCompanyList();
  }, []);

  const handleCompareModelClose = () => {
    setCompareStartDate1(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate1(moment(new Date()).format("yyyy-MM-DD"));
    setCompareStartDate(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate(moment(new Date()).format("yyyy-MM-DD"));
    setSelectedCompany1("");
    setSelectedCompany2("");
    setCompareModel(false);
  };

  const getCompanyList = async () => {
    const res = await fetchCompanyList();
    if (res.status) {
      setCompanyList(res.data);
    }
  };

  const saveAsImage = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
      });
    }
  };

  useEffect(() => {
    getByMedia();
  }, [startDate, endDate, refresh, refreshValue, themeType, viewType]);

  return (
    <>
      {isExpand && <DropBox />}

      <div class={`themeCard ${isExpand ? "expand" : ""}`}>
        <div class="CardContainer">
          <header class="cardHeader">
            <ul class="headerRightItems themes-btns">
              <li
                class={
                  viewType == 1
                    ? "theme-btn t-theme active"
                    : "theme-btn t-theme"
                }
                onClick={() => {
                  setViewType(1);
                }}
              >
                By Media Type
              </li>
              <li
                class={viewType == 2 ? "theme-btn  active" : "theme-btn"}
                onClick={() => {
                  setViewType(2);
                }}
              >
                By Video Type
              </li>
            </ul>

            <div class="headerRight">
              <ul class="headerRightItems themes-btns">
                <li
                  class={
                    themeType == 1
                      ? "theme-btn t-theme active"
                      : "theme-btn t-theme"
                  }
                  onClick={() => {
                    setThemeType(1);
                  }}
                >
                  Theme
                </li>
                <li
                  class={themeType == 2 ? "theme-btn  active" : "theme-btn"}
                  onClick={() => {
                    setThemeType(2);
                  }}
                >
                  Platform
                </li>
                <li
                  class={
                    themeType == 3
                      ? "theme-btn sstheme active"
                      : "theme-btn sstheme"
                  }
                  onClick={() => {
                    setThemeType(3);
                  }}
                >
                  Company
                </li>
              </ul>
              <ul class="headerRightItems">
                <li onClick={setRefresh}>
                  {" "}
                  <a>
                    <img src={bar} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    setShowTable(!showTable);
                  }}
                >
                  {" "}
                  <a>
                    <img src={cIcon} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    getCompareByMedia();
                    setCompareModel(true);
                  }}
                >
                  <a>
                    <img src={compare} alt="" />
                  </a>
                </li>
                <li onClick={toggleInfo}>
                  {" "}
                  <a>
                    <img src={fluent} alt="" />
                  </a>
                  {isInfoVisible && (
                    <div className="chartInfo">
                      <div className="infottl">Information</div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                    </div>
                  )}
                </li>
                <li
                  onClick={() => {
                    saveAsImage();
                  }}
                >
                  {" "}
                  <a>
                    <img src={carbonImg} alt="" />
                  </a>
                </li>

                {/* <li onClick={setRefresh}>
                {" "}
                <a>
                  <img src={carbonImg} alt="" />
                </a>
              </li> */}
                <li
                  className={`${isExpand ? "active" : ""}`}
                  onClick={showExpand}
                >
                  {" "}
                  <a>
                    <img src={isExpand ? shrink : pepicon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </header>
          <div class="card-body">
            {/* <ReactECharts option={chartState.options} /> */}
            {loading ? (
              <div ref={chartRef} className="chartBox">
                {showTable ? (
                  <div className="tableBox">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          {tableData[bigestValueIndex]?.data?.map((value) => {
                            return (
                              <th>
                                {viewType == 2 && themeType == 1
                                  ? value?._id
                                  : value?.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tableValue?.map((value) => {
                          return (
                            <tr>
                              <td>{value?.name}</td>
                              {value?.data.map((value) => {
                                return <td>{value?.count ?? 0}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <ReactECharts
                    echarts={echarts}
                    option={chartState.options}
                    style={isExpand ? { height: "600px", width: "100%" } : {}}
                  />
                )}{" "}
              </div>
            ) : (
              <Shimmer />
            )}
          </div>
        </div>
        {/* compare model */}
        <Modal
          centered
          show={compareModel}
          onHide={() => {
            handleCompareModelClose();
          }}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
          className="modal-lg customModel"
        >
          <Modal.Header closeButton>
            <Modal.Title>Compare</Modal.Title>
          </Modal.Header>
          <Modal.Body className="compbody">
            <div className="compBox">
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen1 ? menuC : menuO}
                        alt=""
                        onClick={() => {
                          setIsMenuOpen1(!isMenuOpen1);
                        }}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen1 ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany1(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          value={themeType1}
                          onChange={(e) => {
                            setThemeType1(e.target.value);
                          }}
                        >
                          {/* <option>select theme type</option> */}
                          <option value={1}>Theme</option>
                          <option value={2}>Platform</option>
                          <option value={3}>Company</option>
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate1).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate1(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate1).format("yyyy-MM-DD")}
                          value={moment(compareEndDate1).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate1(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading1 ? (
                    <ReactECharts option={compareChartState1.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen ? menuC : menuO}
                        alt=""
                        onClick={toggleMenu}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany2(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          value={themeType2}
                          onChange={(e) => {
                            setThemeType2(e.target.value);
                          }}
                        >
                          {/* <option>select theme type</option> */}
                          <option value={1}>Theme</option>
                          <option value={2}>Platform</option>
                          <option value={3}>Company</option>
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate).format("yyyy-MM-DD")}
                          value={moment(compareEndDate).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading ? (
                    <ReactECharts option={compareChartState.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ByMediaAndByVideo;
