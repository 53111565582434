import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/homePage";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import SideMenu from "./components/sideMenu";
import AddCompany from "./pages/addCompany";
import Login from "./pages/login";
import Private from "./components/routes/privateRoutes";
import ThemePage from "./pages/themePage";
import EngagementPage from "./pages/engagementPage";
import MediaPage from "./pages/mediaPage";

function App() {
  return (
    <div className="main">
      <Provider store={store}>
        {/* <SideMenu /> */}
        {/* <HeaderMenu /> */}

        <Routes>
          <Route exact path="/" element={<Private Component={HomePage} />} />
          <Route
            exact
            path="/theme"
            element={<Private Component={ThemePage} />}
          />
          <Route
            exact
            path="/engagement"
            element={<Private Component={EngagementPage} />}
          />
          <Route
            exact
            path="/media"
            element={<Private Component={MediaPage} />}
          />
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </Provider>
    </div>
  );
}

export default App;
