import { useEffect, useRef, useState } from "react";
import pIcon from "../../assets/images/3p.svg";
import cIcon from "../../assets/images/table_icon.svg";
import fluent from "../../assets/images/info_icon.svg";
import carbonImg from "../../assets/images/image_icon.svg";
import pepicon from "../../assets/images/full_view_icon.svg";
import compare from "../../assets/images/compare_icon.svg";
import shrink from "../../assets/images/shrink.png";
import menuO from "../../assets/images/menu-o.png";
import menuC from "../../assets/images/menu-c.png";
import DropBox from "../dropBox";

import ReactECharts from "echarts-for-react";
import * as echarts from "echarts"; // Import ECharts library
import { fetchByPlatFormTimeWise, fetchCompanyList } from "../../services/home";
import Shimmer from "../shimmer";
import html2canvas from "html2canvas";
import { Modal } from "react-bootstrap";
import moment from "momnet";
import { useSelector } from "react-redux";
import { fetchAnalysisThemePlatform } from "../../services/theme";

const AnalysisThemeByPlatform = ({ startDate, endDate }) => {
  const chartRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [compareModel, setCompareModel] = useState(false);
  const refreshValue = useSelector((state) => state.counter.value);
  const [refresh, setRefresh] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [bigestValueIndex, setBigestValuleIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [tableValue, setTableValue] = useState([]);
  const [isExpand, setIsExpand] = useState(false);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany1, setSelectedCompany1] = useState("");
  const [selectedCompany2, setSelectedCompany2] = useState("");
  const [themeType, setThemeType] = useState(1);
  const [themeType1, setThemeType1] = useState(1);
  const [themeType2, setThemeType2] = useState(1);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleInfo = () => {
    setInfoVisible(!isInfoVisible);
  };
  const showExpand = () => {
    setIsExpand(!isExpand);
  };

  const [chartState, setChartState] = useState({
    options: {
      tooltip: {
        position: "top",
        formatter: (params) => `Value: ${params.value[2]}`,
      },
      // legend: {
      //   // Try 'horizontal'
      //   orient: "horizontal",
      //   bottom: 20,
      //   // top: 20,
      // },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {},
      //   },
      // },
      grid: {
        left: "1%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: [
          "X-Axis Label 1",
          "X-Axis Label 2",
          "X-Axis Label 3" /* Add more labels here... */,
        ],
        splitArea: {
          show: true,
        },
        axisLabel: {
          interval: 0,
          rotate: 20, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "category",
        data: [
          "Y-Axis Label 1",
          "Y-Axis Label 2",
          "Y-Axis Label 3" /* Add more labels here... */,
        ],
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        min: 0,
        max: 100,
        calculable: true,
        orient: "horizontal",
        left: "center",
        bottom: 0,
        show: false,
        inRange: {
          color: [
            "#FCF8F8",
            "#F9D5CB",
            "#F3AB97",
            "#EA6C4A",
            "#E54217",
            "#CB3A14",
            "#651D0A",
          ], // Custom colors for the heatmap
        },
      },
      series: [
        {
          name: "Heatmap Chart",
          type: "heatmap",
          data: [],
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    },
  });

  const [compareStartDate1, setCompareStartDate1] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate1, setCompareEndDate1] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading1, setCompareLoading1] = useState(false);
  const [compareChartState1, setCompareChartState1] = useState({
    options: {
      tooltip: {
        position: "top",
        formatter: (params) => `Value: ${params.value[2]}`,
      },
      grid: {
        height: "50%",
        y: "10%",
      },
      xAxis: {
        type: "category",
        data: [
          "X-Axis Label 1",
          "X-Axis Label 2",
          "X-Axis Label 3" /* Add more labels here... */,
        ],
        splitArea: {
          show: true,
        },
        axisLabel: {
          interval: 0,
          rotate: 50, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "category",
        data: [
          "Y-Axis Label 1",
          "Y-Axis Label 2",
          "Y-Axis Label 3" /* Add more labels here... */,
        ],
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        min: 0,
        max: 100,
        calculable: true,
        orient: "horizontal",
        left: "center",
        bottom: "15%",
        show: false,
        inRange: {
          color: [
            "#FCF8F8",
            "#F9D5CB",
            "#F3AB97",
            "#EA6C4A",
            "#E54217",
            "#CB3A14",
            "#651D0A",
          ], // Custom colors for the heatmap
        },
      },
      series: [
        {
          name: "Heatmap Chart",
          type: "heatmap",
          data: [],
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    },
  });

  const [compareStartDate, setCompareStartDate] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate, setCompareEndDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading, setCompareLoading] = useState(false);
  const [compareChartState, setCompareChartState] = useState({
    options: {
      tooltip: {
        position: "top",
        formatter: (params) => `Value: ${params.value[2]}`,
      },
      grid: {
        height: "50%",
        y: "10%",
      },
      xAxis: {
        type: "category",
        data: [
          "X-Axis Label 1",
          "X-Axis Label 2",
          "X-Axis Label 3" /* Add more labels here... */,
        ],
        splitArea: {
          show: true,
        },
        axisLabel: {
          interval: 0,
          rotate: 50, //If the label names are too long you can manage this by rotating the label.
        },
      },
      yAxis: {
        type: "category",
        data: [
          "Y-Axis Label 1",
          "Y-Axis Label 2",
          "Y-Axis Label 3" /* Add more labels here... */,
        ],
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        min: 0,
        max: 100,
        calculable: true,
        orient: "horizontal",
        left: "center",
        bottom: "15%",
        show: false,
        inRange: {
          color: [
            "#FCF8F8",
            "#F9D5CB",
            "#F3AB97",
            "#EA6C4A",
            "#E54217",
            "#CB3A14",
            "#651D0A",
          ], // Custom colors for the heatmap
        },
      },
      series: [
        {
          name: "Heatmap Chart",
          type: "heatmap",
          data: [],
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    },
  });

  const saveAsImage = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
      });
    }
  };

  const getByPlatFormTimeWise = async () => {
    setLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: startDate,
      endDate: endDate,
      themesType: themeType,
    };
    const res = await fetchAnalysisThemePlatform(payload);
    if (res.status) {
      setTableData(res.data);

      let indexId = 0;
      res.data.map((value, index) => {
        if (res.data[0].listThemes?.length <= value.listThemes?.length) {
          setBigestValuleIndex(index);
          indexId = index;
        }
      });
      let tempTableData = [];
      res.data.map((value) => {
        let tt = [];
        [...Array(res.data[indexId]?.listThemes?.length).keys()].map(
          (value) => {
            tt.push({});
          }
        );
        res.data[indexId].listThemes.map((themeValue, index) => {
          value.listThemes.map((thmValue, thmIndex) => {
            if (themeValue.data == thmValue.data) {
              tt.splice(
                res.data[indexId].listThemes.findIndex(
                  (x) => x.data === thmValue.data
                ),
                1,
                thmValue
              );
            }
          });
        });

        tempTableData.push({ name: value.name, listThemes: tt });
      });

      /// table header
      let tempHeader = [];
      res.data.map((value, index) => {
        value.listThemes.map((postValue) => {
          if (!tempHeader.includes(postValue.data)) {
            tempHeader.push(postValue.data);
          }
        });
      });

      setTableValue(tempTableData);

      let platFormArray = res.data.map((element) => {
        return element.name;
      });

      let xAxisArray = tempHeader.map((element) => {
        return element;
      });

      chartState.options.series[0].data = [];
      res.data.forEach((element, index) => {
        xAxisArray.map((axisData, axisIndex) => {
          [...Array(res.data[bigestValueIndex]?.listThemes?.length).keys()].map(
            (indDEx, valueIndex) => {
              let idx = tempHeader.indexOf(
                element.listThemes[valueIndex]?.data
              );
              if (idx >= 0) {
                chartState.options.series[0].data.push([
                  idx,
                  index,
                  res.data[index]?.listThemes[valueIndex]?.count,
                ]);
              }
            }
          );
        });
      });

      chartState.options.yAxis.data = platFormArray;
      chartState.options.xAxis.data = xAxisArray;

      setChartState(chartState);
      setLoading(true);
    }
  };

  const getCompareByPlatFormTimeWise1 = async () => {
    setCompareLoading1(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate1,
      endDate: compareEndDate1,
      themesType: themeType1,
    };
    if (selectedCompany1) {
      payload.companyIds = [selectedCompany1];
    }
    const res = await fetchAnalysisThemePlatform(payload);
    if (res.status) {
      let platFormArray = res.data.map((element) => {
        return element.name;
      });

      let xAxisArray = res.data[0].listThemes.map((element) => {
        return element.data;
      });

      let bigValue = 0;

      res.data.map((value, index) => {
        if (res.data[0].listThemes?.length < value.listThemes?.length) {
          bigValue = index;
        }
      });

      compareChartState1.options.series[0].data = [];
      res.data.forEach((element, index) => {
        xAxisArray.map((axisData, axisIndex) => {
          [...Array(res.data[bigValue]?.listThemes?.length).keys()].map(
            (indDEx, valueIndex) => {
              let idx = xAxisArray.indexOf(
                element.listThemes[valueIndex]?.data
              );
              if (idx >= 0) {
                compareChartState1.options.series[0].data.push([
                  idx,
                  index,
                  res.data[index]?.listThemes[valueIndex]?.count,
                ]);
              }
            }
          );
        });
      });

      compareChartState1.options.yAxis.data = platFormArray;
      compareChartState1.options.xAxis.data = xAxisArray;

      setCompareChartState1(compareChartState1);
      setCompareLoading1(true);
    }
  };

  const getCompareByPlatFormTimeWise = async () => {
    setCompareLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate,
      endDate: compareEndDate,
      themesType: themeType2,
    };
    if (selectedCompany2) {
      payload.companyIds = [selectedCompany2];
    }
    const res = await fetchAnalysisThemePlatform(payload);
    if (res.status) {
      let platFormArray = res.data.map((element) => {
        return element.name;
      });

      let xAxisArray = res.data[0].listThemes.map((element) => {
        return element.data;
      });

      let bigValue = 0;

      res.data.map((value, index) => {
        if (res.data[0].listThemes?.length < value.listThemes?.length) {
          bigValue = index;
        }
      });

      compareChartState.options.series[0].data = [];
      res.data.forEach((element, index) => {
        xAxisArray.map((axisData, axisIndex) => {
          [...Array(res.data[bigValue]?.listThemes?.length).keys()].map(
            (indDEx, valueIndex) => {
              let idx = xAxisArray.indexOf(
                element.listThemes[valueIndex]?.data
              );
              if (idx >= 0) {
                compareChartState.options.series[0].data.push([
                  idx,
                  index,
                  res.data[index]?.listThemes[valueIndex]?.count,
                ]);
              }
            }
          );
        });
      });

      // compareChartState.options.series[0].data = [];
      // res.data.forEach((element, index) => {
      //   element.listThemes.map((data, valueIndex) => {
      //     compareChartState.options.series[0].data.push([
      //       valueIndex,
      //       index,
      //       data?.count ?? 0,
      //     ]);
      //   });
      // });

      compareChartState.options.yAxis.data = platFormArray;
      compareChartState.options.xAxis.data = xAxisArray;

      setCompareChartState(compareChartState);
      setCompareLoading(true);
    }
  };

  const getCompanyList = async () => {
    const res = await fetchCompanyList();
    if (res.status) {
      setCompanyList(res.data);
    }
  };

  useEffect(() => {
    getByPlatFormTimeWise();
  }, [startDate, endDate, refresh, refreshValue, themeType]);

  useEffect(() => {
    getCompareByPlatFormTimeWise();
  }, [compareEndDate, compareStartDate, selectedCompany2, themeType2]);

  useEffect(() => {
    getCompareByPlatFormTimeWise1();
  }, [compareEndDate1, compareStartDate1, selectedCompany1, themeType1]);

  useEffect(() => {
    getCompanyList();
  }, []);

  const handleCompareModelClose = () => {
    setCompareStartDate1(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate1(moment(new Date()).format("yyyy-MM-DD"));
    setCompareStartDate(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate(moment(new Date()).format("yyyy-MM-DD"));
    setSelectedCompany1("");
    setSelectedCompany2("");
    setCompareModel(false);
  };

  return (
    <>
      {isExpand && <DropBox />}

      <div class={`themeCard ${isExpand ? "expand" : ""}`}>
        <div class="CardContainer">
          <header class="cardHeader">
            <h5>Analysis By Theme /Platform</h5>

            <div class="headerRight">
              <ul class="headerRightItems">
                <li
                  class={
                    themeType == 1
                      ? "theme-btn t-theme active"
                      : "theme-btn t-theme"
                  }
                  onClick={() => {
                    setThemeType(1);
                  }}
                >
                  Theme
                </li>
                <li
                  class={themeType == 2 ? "theme-btn  active" : "theme-btn"}
                  onClick={() => {
                    setThemeType(2);
                  }}
                >
                  Sub Theme
                </li>
                <li
                  class={
                    themeType == 3
                      ? "theme-btn sstheme active"
                      : "theme-btn sstheme"
                  }
                  onClick={() => {
                    setThemeType(3);
                  }}
                >
                  Sub Sub Theme
                </li>
              </ul>
              <ul class="headerRightItems">
                <li onClick={setRefresh}>
                  {" "}
                  <a>
                    <img src={pIcon} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    setShowTable(!showTable);
                  }}
                >
                  {" "}
                  <a>
                    <img src={cIcon} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    getCompareByPlatFormTimeWise();
                    setCompareModel(true);
                  }}
                >
                  {" "}
                  <a>
                    <img src={compare} alt="" />
                  </a>
                </li>
                <li onClick={toggleInfo}>
                  {" "}
                  <a>
                    <img src={fluent} alt="" />
                  </a>
                  {isInfoVisible && (
                    <div className="chartInfo">
                      <div className="infottl">Information</div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                    </div>
                  )}
                </li>
                <li
                  onClick={() => {
                    saveAsImage();
                  }}
                >
                  {" "}
                  <a>
                    <img src={carbonImg} alt="" />
                  </a>
                </li>
                <li
                  className={`${isExpand ? "active" : ""}`}
                  onClick={showExpand}
                >
                  {" "}
                  <a>
                    <img src={isExpand ? shrink : pepicon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </header>
          <div class="card-body">
            {loading ? (
              <div ref={chartRef} className="chartBox">
                {showTable ? (
                  <div className="tableBox">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          {tableData[bigestValueIndex].listThemes.map(
                            (value) => {
                              return <th>{value.data}</th>;
                            }
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {tableData.map((value) => {
                          return (
                            <tr>
                              <td>{value.name}</td>
                              {[
                                ...Array(
                                  tableData[bigestValueIndex]?.listThemes
                                    ?.length
                                ).keys(),
                              ]?.map((indexData, ind) => {
                                return (
                                  <td>{value?.listThemes[ind]?.count ?? 0}</td>
                                );
                              })}
                            
                            </tr>
                          );
                        })} */}
                        {tableValue?.map((value) => {
                          return (
                            <tr>
                              <td>{value?.name}</td>
                              {value?.listThemes.map((value) => {
                                return <td>{value?.count ?? 0}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <ReactECharts
                    echarts={echarts}
                    style={
                      isExpand
                        ? { height: "600px", width: "100%" }
                        : { height: "400px" }
                    }
                    option={chartState.options}
                  />
                )}
              </div>
            ) : (
              <Shimmer />
            )}
          </div>
        </div>
        {/* compare model */}
        <Modal
          centered
          show={compareModel}
          onHide={() => {
            handleCompareModelClose();
          }}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
          className="modal-lg customModel"
        >
          <Modal.Header closeButton>
            <Modal.Title>Compare</Modal.Title>
          </Modal.Header>
          <Modal.Body className="compbody">
            <div className="compBox">
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen1 ? menuC : menuO}
                        alt=""
                        onClick={() => {
                          setIsMenuOpen1(!isMenuOpen1);
                        }}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen1 ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany1(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          value={themeType1}
                          onChange={(e) => {
                            setThemeType1(e.target.value);
                          }}
                        >
                          {/* <option>select theme type</option> */}
                          <option value={1}>Theme</option>
                          <option value={2}>Sub Theme</option>
                          <option value={3}>Sub sub Theme</option>
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate1).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate1(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate1).format("yyyy-MM-DD")}
                          value={moment(compareEndDate1).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate1(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading1 ? (
                    <ReactECharts option={compareChartState1.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen ? menuC : menuO}
                        alt=""
                        onClick={toggleMenu}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany2(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          value={themeType2}
                          onChange={(e) => {
                            setThemeType2(e.target.value);
                          }}
                        >
                          {/* <option>select theme type</option> */}
                          <option value={1}>Theme</option>
                          <option value={2}>Sub Theme</option>
                          <option value={3}>Sub sub Theme</option>
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate).format("yyyy-MM-DD")}
                          value={moment(compareEndDate).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading ? (
                    <ReactECharts option={compareChartState.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AnalysisThemeByPlatform;
