import React, { useEffect, useState } from "react";
import FilterPage from "../components/homeComponent/filterPage";
import HeaderCard from "../components/homeComponent/headerCard";
import plusIcon from "../assets/images/plus.svg";
import ByPostCard from "../components/homeComponent/byPostCard";
import ByCompanyCard from "../components/homeComponent/byCompanyCard";
import ByPlatFormCard from "../components/homeComponent/byPlatFormCard";
import ByPlatForm2Card from "../components/homeComponent/byPlatForm2Card";
import ByThemeCard from "../components/homeComponent/byTheme";
import PostByTimeDateCard from "../components/homeComponent/postByTimeDateCard";
import ByMediaTypeCard from "../components/homeComponent/byMediaTypeCard";
import ByVideoTypeCard from "../components/homeComponent/byVideoTypeCard";
import PostByTimeDateCard2 from "../components/homeComponent/postByTimeDateCard2";
import PostByTimeDateCard3 from "../components/homeComponent/postByTimeDateCard3";
import Footer from "../components/footer";
import HeaderMenu from "../components/headerMenu";
import moment from "momnet";
import { changeCompanyList, fetchCompanyList } from "../services/home";
import { useDispatch } from "react-redux";
import { refresh } from "../redux/slice/filterRefreshSlice";
import PostByBrandByHour from "../components/homeComponent/byBrandByHour";

const HomePage = ({ startDate, endDate }) => {
  useEffect(() => {
    console.log("JODIJF");
  }, [startDate, endDate]);
  return (
    <section class="content sec">
      <div class="cardRow twoCol">
        <ByPostCard startDate={startDate} endDate={endDate} />
        <ByCompanyCard startDate={startDate} endDate={endDate} />
        <ByPlatFormCard startDate={startDate} endDate={endDate} />
        <ByPlatForm2Card startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <ByThemeCard startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <PostByTimeDateCard startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow twoCol">
        <ByMediaTypeCard startDate={startDate} endDate={endDate} />
        <ByVideoTypeCard startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <PostByTimeDateCard2 startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <PostByBrandByHour startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <PostByTimeDateCard3 startDate={startDate} endDate={endDate} />
      </div>
      <Footer />
    </section>
  );
};

export default HomePage;
