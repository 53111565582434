import { Navigate } from "react-router-dom";
import SideMenu from "../sideMenu";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import moment from "momnet";
import { refresh } from "../../redux/slice/filterRefreshSlice";
import plusIcon from "../../assets/images/plus.svg";
import HeaderMenu from "../headerMenu";
import FilterPage from "../homeComponent/filterPage";
import HeaderCard from "../homeComponent/headerCard";
import HomePage from "../../pages/homePage";
import { changeCompanyList, fetchCompanyList } from "../../services/home";

const Private = ({ Component }) => {
  const auth = true; //your logic

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("yyyy-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [showCompanyAdd, setShowCompnayAdd] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [onChangeCompany, setOnChangeCompnay] = useState(false);

  const onDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onSelectCompany = (id) => {
    const checkExist = selectedCompany.some((value) => value === id);
    if (checkExist) {
      console.log("sdfsdf", selectedCompany.length > 2, selectedCompany);
      if (selectedCompany.length > 1) {
        setSelectedCompany(
          selectedCompany.filter((value) => {
            return value !== id;
          })
        );
      }
    } else {
      setSelectedCompany([...selectedCompany, id]);
    }
    setTimeout(() => {
      dispatch(refresh());
    }, 1000);
  };

  const getCompanyList = async () => {
    const res = await fetchCompanyList();
    if (res.status) {
      setCompanyList(res.data);
      if (!selectedCompany.length) {
        res.data.map((value) => {
          if (value.public) {
            selectedCompany.push(value._id);
          }
        });
      }
    }
  };

  const updateCompanyList = async () => {
    const payload = {
      companyId: selectedCompany,
    };
    const res = await changeCompanyList(payload);
    if (res.status) {
      setOnChangeCompnay(!onChangeCompany);
      getCompanyList();
    }
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    if (selectedCompany.length) {
      updateCompanyList();
    }
  }, [selectedCompany]);

  return auth ? (
    <>
      <SideMenu />
      <section class="content">
        <HeaderMenu onDateChange={onDateChange} />
        <FilterPage />
        <div class="cards">
          <div class="cardsItems">
            <HeaderCard
              onChangeCompany={onChangeCompany}
              startDate={startDate}
              endDate={endDate}
            />
            <div class="addCard">
              <div
                class="addCompany"
                onClick={() => {
                  setShowCompnayAdd(!showCompanyAdd);
                }}
              >
                <a>
                  <img src={plusIcon} alt="" />
                  company
                </a>
              </div>
              <div class={`addCompanyFormBox ${showCompanyAdd ? "show" : ""}`}>
                <div class="addForm">
                  <ul>
                    {companyList &&
                      companyList.map((data) => {
                        return (
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectedCompany.some(
                                  (value) => value === data._id
                                )}
                                value={data._id}
                                onChange={(e) => {
                                  onSelectCompany(e.target.value);
                                }}
                              />{" "}
                              {data.name}
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Component startDate={startDate} endDate={endDate} />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default Private;
