import { requestWithToken } from "../helpers/helpers";

const fetchTotalPostCount = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/totalPostCount`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchChanelWisePost = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/admin/corporateChannelWisePost",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByPost = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPostTypePost`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllUniqueByPost = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/postByRepeatOrUnique`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByCompanyPost = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/companyPostsByUniqueType`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByPlatFormPost = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPlatFormPost`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchBylastOneMonthReport = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/corporateChannelWisePost`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchBylastOneMonthReportByCompany = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/corporateDetails/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByPlatFormPostLastWeek = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/companyPostByEngagement`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByPostLastSevenDaysHourly = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPostLastSevenDaysHourly`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByPlatFormTimeWise = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPlatFormTimeWise`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchPostByBrandByHour = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byCorporateTimeWise`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchThemesReport = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/postsByTheme`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByMediaTypePost = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/byPostTypePost`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchByVideoTypePost = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/admin/videoReport",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchCompanyList = async () => {
  try {
    const response = await requestWithToken("GET", "/admin/company");

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const changeCompanyList = async (payload) => {
  try {
    const response = await requestWithToken("PUT", "/admin/company", payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchClientHandler = async () => {
  try {
    const response = await requestWithToken("GET", "/admin/companyHandler");

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchThemes = async () => {
  try {
    const response = await requestWithToken("GET", "/admin/themes");

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchChanelWisePost,
  fetchByPost,
  fetchByCompanyPost,
  fetchByPlatFormPost,
  fetchByPlatFormPostLastWeek,
  fetchBylastOneMonthReport,
  fetchByPostLastSevenDaysHourly,
  fetchByPlatFormTimeWise,
  fetchThemesReport,
  fetchByMediaTypePost,
  fetchByVideoTypePost,
  fetchCompanyList,
  changeCompanyList,
  fetchClientHandler,
  fetchThemes,
  fetchAllUniqueByPost,
  fetchTotalPostCount,
  fetchBylastOneMonthReportByCompany,
  fetchPostByBrandByHour,
};
