import { useEffect, useRef, useState } from "react";
import pIcon from "../../assets/images/2p.svg";
import cIcon from "../../assets/images/table_icon.svg";
import fluent from "../../assets/images/info_icon.svg";
import carbonImg from "../../assets/images/image_icon.svg";
import pepicon from "../../assets/images/full_view_icon.svg";
import compare from "../../assets/images/compare_icon.svg";
import shrink from "../../assets/images/shrink.png";
import menuO from "../../assets/images/menu-o.png";
import menuC from "../../assets/images/menu-c.png";
import DropBox from "../dropBox";

import ReactECharts from "echarts-for-react";
import * as echarts from "echarts"; // Import ECharts library
import { fetchByTheme, fetchByThemeDetails } from "../../services/engagement";
import { useSelector } from "react-redux";
import Shimmer from "../shimmer";
import html2canvas from "html2canvas";
import { Modal } from "react-bootstrap";
import moment from "momnet";
import { fetchCompanyList } from "../../services/home";

const ByThemeCard = ({ startDate, endDate }) => {
  const chartRef = useRef(null);
  const refreshValue = useSelector((state) => state.counter.value);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableName, setTableName] = useState("");
  const [showDetailModel, setShowDetailModel] = useState(false);
  const [companyDetailLoading, setCompnayDetailModelLoading] = useState(false);
  const [companyDetailList, setCompanyDetailList] = useState([]);
  const [compareModel, setCompareModel] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isExpand, setIsExpand] = useState(false);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany1, setSelectedCompany1] = useState("");
  const [selectedCompany2, setSelectedCompany2] = useState("");
  const [themeType, setThemeType] = useState(1);
  const [themeType1, setThemeType1] = useState(1);
  const [themeType2, setThemeType2] = useState(1);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleInfo = () => {
    setInfoVisible(!isInfoVisible);
  };
  const showExpand = () => {
    setIsExpand(!isExpand);
  };

  const [chartState, setChartState] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#283b56",
          },
        },
      },
      legend: {
        // Try 'horizontal'
        orient: "horizontal",
        bottom: 20,
        // top: 20,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "50",
        containLabel: true,
      },

      xAxis: [
        {
          type: "category",
          data: [],
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            interval: 0,
            rotate: 20, //If the label names are too long you can manage this by rotating the label.
          },
        },
        {
          type: "category",
          data: [],
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            interval: 0,
            rotate: 20, //If the label names are too long you can manage this by rotating the label.
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          scale: true,
          name: "",
          splitLine: {
            show: false,
          },
        },
        {
          type: "value",
          scale: true,
          name: "",
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "Post",
          type: "bar",
          // xAxisIndex: 1,
          yAxisIndex: 1,
          data: [],
        },
        {
          name: "Engagement",
          type: "line",
          data: [],
        },
      ],
    },
  });

  const [compareStartDate1, setCompareStartDate1] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate1, setCompareEndDate1] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading1, setCompareLoading1] = useState(false);
  const [compareChartState1, setCompareChartState1] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#283b56",
          },
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {},
      //   },
      // },
      // toolbox: {
      //   show: false,
      //   feature: {
      //     dataView: { readOnly: false },
      //     restore: {},
      //   },
      // },
      dataZoom: {
        show: false,
        start: 0,
        end: 100,
      },
      xAxis: [
        {
          axisLabel: {
            rotate: 20,
          },
          type: "category",
          boundaryGap: true,
          data: [],
          axisLabel: {
            interval: 0,
            rotate: 30, //If the label names are too long you can manage this by rotating the label.
          },
        },
        {
          type: "category",
          boundaryGap: true,
          data: [],
          axisLabel: {
            interval: 0,
            rotate: 30, //If the label names are too long you can manage this by rotating the label.
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          scale: true,
          name: "",
          splitLine: {
            show: false,
          },
        },
        {
          type: "value",
          scale: true,
          name: "",
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "Post",
          type: "bar",
          yAxisIndex: 1,
          data: [],
        },
        {
          name: "Engagement",
          type: "line",
          data: [],
        },
      ],
    },
  });

  const [compareStartDate, setCompareStartDate] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate, setCompareEndDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading, setCompareLoading] = useState(false);
  const [compareChartState, setCompareChartState] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#283b56",
          },
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },

      // toolbox: {
      //   feature: {
      //     saveAsImage: {},
      //   },
      // },
      // toolbox: {
      //   show: false,
      //   feature: {
      //     dataView: { readOnly: false },
      //     restore: {},
      //   },
      // },
      dataZoom: {
        show: false,
        start: 0,
        end: 100,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: true,
          data: [],
          axisLabel: {
            interval: 0,
            rotate: 30, //If the label names are too long you can manage this by rotating the label.
          },
        },
        {
          type: "category",
          boundaryGap: true,
          data: [],
          axisLabel: {
            interval: 0,
            rotate: 30, //If the label names are too long you can manage this by rotating the label.
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          scale: true,
          name: "",
          splitLine: {
            show: false,
          },
        },
        {
          type: "value",
          scale: true,
          name: "",
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "Post",
          type: "bar",
          // xAxisIndex: 1,
          yAxisIndex: 1,
          data: [],
        },
        {
          name: "Engagement",
          type: "line",
          data: [],
        },
      ],
    },
  });

  // Event handler for series click
  const handleSeriesClick = (params) => {
    if (params.seriesType === "bar") {
      setTableName(params.name);
      getByCompanyDetailsPost(params.data.themeId, params.data.data);
      // Handle click event for bar series
      console.log("Clicked on bar series:", params);
    }
  };

  const getByCompanyPost = async () => {
    setLoading(false);
    const payload = {
      // handler: ["64dc7dbdab54658ff5eb6096", "64dc7dc4ab54658ff5eb6444"],
      // themes: ["64531f4ec8f132e2b8609643"],
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      startDate: startDate,
      endDate: endDate,
      themesType: themeType,
    };
    const res = await fetchByTheme(payload);
    if (res.status) {
      setTableData(res.data);
      let names = res.data.map((element) => {
        return element.data;
      });

      let count = res.data.map((element) => {
        return {
          value: Number(element.count),
          themeId: element.themesId,
          data: element.data,
        };
      });

      let engArray = res.data.map((element) => {
        return Number(element.engScore).toFixed(2);
      });

      chartState.options.xAxis[0].data = names;
      chartState.options.series[0].data = count;
      chartState.options.series[1].data = engArray;

      setChartState(chartState);
      setLoading(true);
    }
  };

  const getByCompanyDetailsPost = async (themeId, dataName) => {
    setShowDetailModel(true);
    setCompnayDetailModelLoading(false);
    const payload = {
      // handler: ["64dc7dbdab54658ff5eb6096", "64dc7dc4ab54658ff5eb6444"],
      // themes: ["64531f4ec8f132e2b8609643"],
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      startDate: startDate,
      endDate: endDate,
      themesType: themeType,
      themesId: themeId,
      data: dataName,
    };
    const res = await fetchByThemeDetails(payload);
    setCompnayDetailModelLoading(true);
    if (res.status) {
      setCompanyDetailList(res.data);
      console.log("JDIOfj", res.data);
    }
  };

  const getCompareByCompanyPost1 = async () => {
    setCompareLoading1(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      //   uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate1,
      endDate: compareEndDate1,
      themesType: themeType1,
    };
    if (selectedCompany1) {
      payload.companyIds = [selectedCompany1];
    }
    const res = await fetchByTheme(payload);
    if (res.status) {
      let names = res.data.map((element) => {
        return element.data;
      });

      let count = res.data.map((element) => {
        return Number(element.count).toFixed(2);
      });

      let engArray = res.data.map((element) => {
        return element.engScore;
      });

      compareChartState1.options.xAxis[0].data = names;
      compareChartState1.options.series[0].data = count;
      compareChartState1.options.series[1].data = engArray;

      setCompareChartState1(compareChartState1);
      setCompareLoading1(true);
    }
  };

  const getCompareByCompanyPost = async () => {
    setCompareLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      //   uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate,
      endDate: compareEndDate,
      themesType: themeType2,
    };
    if (selectedCompany2) {
      payload.companyIds = [selectedCompany2];
    }
    const res = await fetchByTheme(payload);
    if (res.status) {
      let names = res.data.map((element) => {
        return element.data;
      });

      let count = res.data.map((element) => {
        return Number(element.count).toFixed(2);
      });

      let engArray = res.data.map((element) => {
        return element.engScore;
      });

      compareChartState.options.xAxis[0].data = names;
      compareChartState.options.series[0].data = count;
      compareChartState.options.series[1].data = engArray;

      setCompareChartState(compareChartState);
      setCompareLoading(true);
    }
  };

  const saveAsImage = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
      });
    }
  };

  const getCompanyList = async () => {
    const res = await fetchCompanyList();
    if (res.status) {
      setCompanyList(res.data);
    }
  };

  useEffect(() => {
    getByCompanyPost();
  }, [startDate, endDate, refreshValue, refresh, themeType]);

  useEffect(() => {
    getCompareByCompanyPost1();
  }, [compareEndDate1, compareStartDate1, selectedCompany1, themeType1]);

  useEffect(() => {
    getCompareByCompanyPost();
  }, [compareEndDate, compareStartDate, selectedCompany2, themeType2]);

  useEffect(() => {
    getCompanyList();
  }, []);

  const handleCompareModelClose = () => {
    setCompareStartDate1(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate1(moment(new Date()).format("yyyy-MM-DD"));
    setCompareStartDate(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate(moment(new Date()).format("yyyy-MM-DD"));
    setSelectedCompany1("");
    setSelectedCompany2("");
    setCompareModel(false);
  };

  return (
    <>
      {isExpand && <DropBox />}

      <div class={`themeCard ${isExpand ? "expand" : ""}`}>
        <div class="CardContainer">
          <header class="cardHeader">
            <h5>By Theme </h5>
            <div class="headerRight">
              <ul class="headerRightItems">
                <li
                  class={
                    themeType == 1
                      ? "theme-btn t-theme active"
                      : "theme-btn t-theme"
                  }
                  onClick={() => {
                    setThemeType(1);
                  }}
                >
                  Theme
                </li>
                <li
                  class={themeType == 2 ? "theme-btn  active" : "theme-btn"}
                  onClick={() => {
                    setThemeType(2);
                  }}
                >
                  Sub Theme
                </li>
                <li
                  class={
                    themeType == 3
                      ? "theme-btn sstheme active"
                      : "theme-btn sstheme"
                  }
                  onClick={() => {
                    setThemeType(3);
                  }}
                >
                  Sub Sub Theme
                </li>
              </ul>
              <ul class="headerRightItems">
                <li onClick={setRefresh}>
                  {" "}
                  <a>
                    <img src={pIcon} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    setShowTable(!showTable);
                  }}
                >
                  {" "}
                  <a>
                    <img src={cIcon} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    getCompareByCompanyPost();
                    setCompareModel(true);
                  }}
                >
                  <a>
                    <img src={compare} alt="" />
                  </a>
                </li>
                <li onClick={toggleInfo}>
                  {" "}
                  <a>
                    <img src={fluent} alt="" />
                  </a>
                  {isInfoVisible && (
                    <div className="chartInfo">
                      <div className="infottl">Information</div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                    </div>
                  )}
                </li>
                <li
                  onClick={() => {
                    saveAsImage();
                  }}
                >
                  {" "}
                  <a>
                    <img src={carbonImg} alt="" />
                  </a>
                </li>
                <li
                  className={`${isExpand ? "active" : ""}`}
                  onClick={showExpand}
                >
                  {" "}
                  <a>
                    <img src={isExpand ? shrink : pepicon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </header>
          <div class="card-body">
            {loading ? (
              <div ref={chartRef} className="chartBox">
                {showTable ? (
                  <div className="tableBox">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Post Count</th>
                          <th>Eng. Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((value) => {
                          return (
                            <tr>
                              <td>{value.data}</td>
                              <td>{value.count}</td>
                              <td>{value.engScore}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <ReactECharts
                    echarts={echarts}
                    style={
                      isExpand
                        ? { height: "600px", width: "100%" }
                        : { height: "400px" }
                    }
                    option={chartState.options}
                    onEvents={{
                      click: handleSeriesClick, // Attach the click event handler
                    }}
                  />
                )}
              </div>
            ) : (
              <Shimmer />
            )}
          </div>
        </div>
        {/* compare model */}
        <Modal
          centered
          show={compareModel}
          onHide={() => {
            handleCompareModelClose();
          }}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
          className="modal-lg customModel"
        >
          <Modal.Header closeButton>
            <Modal.Title>Compare</Modal.Title>
          </Modal.Header>
          <Modal.Body className="compbody">
            <div className="compBox">
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen1 ? menuC : menuO}
                        alt=""
                        onClick={() => {
                          setIsMenuOpen1(!isMenuOpen1);
                        }}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen1 ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany1(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          value={themeType1}
                          onChange={(e) => {
                            setThemeType1(e.target.value);
                          }}
                        >
                          {/* <option>select theme type</option> */}
                          <option value={1}>Theme</option>
                          <option value={2}>Sub Theme</option>
                          <option value={3}>Sub sub Theme</option>
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate1).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate1(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate1).format("yyyy-MM-DD")}
                          value={moment(compareEndDate1).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate1(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading1 ? (
                    <ReactECharts option={compareChartState1.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen ? menuC : menuO}
                        alt=""
                        onClick={toggleMenu}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany2(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          value={themeType2}
                          onChange={(e) => {
                            setThemeType2(e.target.value);
                          }}
                        >
                          {/* <option>select theme type</option> */}
                          <option value={1}>Theme</option>
                          <option value={2}>Sub Theme</option>
                          <option value={3}>Sub sub Theme</option>
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate).format("yyyy-MM-DD")}
                          value={moment(compareEndDate).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading ? (
                    <ReactECharts option={compareChartState.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        {/* detail model */}
        <Modal
          centered
          show={showDetailModel}
          onHide={() => {
            setShowDetailModel(false);
          }}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
          className="modal-lg customModel"
        >
          <Modal.Header closeButton>
            <Modal.Title>{tableName} Detail Table</Modal.Title>
          </Modal.Header>
          <Modal.Body className="compbody">
            <div class="card-body">
              {companyDetailLoading ? (
                <div className="tableBox">
                  <table>
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th>Post</th>
                        <th>Engagement Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyDetailList.map((value) => {
                        return (
                          <tr>
                            <td>{value.companyDetails.name}</td>
                            <td>{value.count}</td>
                            <td>{Number(value.engScore).toFixed(2)}</td>
                          </tr>
                        );
                      })}
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <Shimmer />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ByThemeCard;
