import { useEffect, useState } from "react";
import DropBox from "../dropBox";
import {
  fetchEngagementBucket,
  fetchEngagementCompanyDetail,
} from "../../services/engagement";
import { useSelector } from "react-redux";
import Shimmer from "../shimmer";
import { Modal } from "react-bootstrap";
const EngagementBucket = ({ startDate, endDate }) => {
  let totalTableValueCount = 0;
  const refreshValue = useSelector((state) => state.counter.value);
  const [compareModel, setCompareModel] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyDetail, setCompanyDetail] = useState({});
  const [companyDetailList, setCompanyDetailList] = useState([]);
  const [companyDetailLoading, setCompanyDetailLoading] = useState(0);

  const [tableData, setTableData] = useState([]);
  const [isExpand, setIsExpand] = useState(false);

  const getByCompanyPost = async () => {
    setLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      startDate: startDate,
      endDate: endDate,
      //   themesType: themeType,
    };
    const res = await fetchEngagementBucket(payload);
    if (res.status) {
      console.log(res.data);
      setTableData(res.data);
      setLoading(true);
    }
  };

  useEffect(() => {
    getByCompanyPost();
  }, [startDate, endDate, refreshValue, refresh]);

  const totalOfColumn = (value) => {
    let sum = 0;
    value.company.forEach((element) => {
      sum += element.count;
    });
    totalTableValueCount += sum;
    return sum;
  };

  const totalOfRow = (value, index) => {
    let sum = 0;
    value.map((data) => {
      data.company.forEach((element, ind) => {
        if (ind === index) {
          sum += element.count;
        }
      });
    });
    return sum;
  };

  const handleCompareModelClose = () => {
    setCompareModel(false);
  };

  const getEngagementCompanyDetail = async () => {
    setCompanyDetailLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value?.id
        ) ?? [],
      startDate: startDate,
      endDate: endDate,
      companyId: companyDetail._id,
      type: companyDetail.type,
    };
    const res = await fetchEngagementCompanyDetail(payload);
    if (res.status) {
      setCompanyDetailList(res.data);
      setCompanyDetailLoading(true);
    }
  };

  useEffect(() => {
    getEngagementCompanyDetail();
  }, [companyDetail]);

  const getTdData = (providerType, countData, header) => {
    let dd = countData.filter((value) => value._id == providerType);
    console.log("DJFIO", dd);
    if (dd.length) {
      header.map((doj) => {
        dd.map((vasdflue) => {
          vasdflue.data.map((valsdf) => {
            if (valsdf.data == doj._id) {
              return <td>kkkkkf</td>;
            }
          });
        });
      });
      return <td>IIJIJIJ </td>;
    } else {
      return <td>lkk</td>;
    }
  };

  return (
    <>
      {isExpand && <DropBox />}

      <div class={`themeCard ${isExpand ? "expand" : ""}`}>
        <div class="CardContainer">
          <header class="cardHeader">
            <h5>Engagement Bucket</h5>
          </header>
          <div class="card-body">
            {loading ? (
              <div className="tableBox">
                <table>
                  <thead>
                    <tr>
                      <th>Engagement Bucket</th>
                      {tableData[0].company.map((value) => {
                        return <th>{value.name}</th>;
                      })}
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((value, index) => {
                      return (
                        <tr>
                          <td>{value.name}</td>
                          {value.company.map((data) => {
                            return (
                              <td
                                onClick={() => {
                                  setCompanyDetail({
                                    _id: data._id,
                                    type: index + 1,
                                    name: data.name,
                                  });

                                  setCompareModel(true);
                                }}
                              >
                                {data.count}
                              </td>
                            );
                          })}
                          <td>{totalOfColumn(value)}</td>
                        </tr>
                      );
                    })}
                    <tr
                      style={{
                        color: "green",
                        background: "#e8d1ff",
                      }}
                    >
                      <td>Total</td>
                      {[...Array(tableData[0].company.length)].map(
                        (value, index) => {
                          return <td>{totalOfRow(tableData, index)}</td>;
                        }
                      )}
                      <td>{totalTableValueCount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <Shimmer />
            )}
          </div>
        </div>
        <Modal
          centered
          show={compareModel}
          onHide={() => {
            handleCompareModelClose();
          }}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
          className="modal-lg customModel"
        >
          <Modal.Header closeButton>
            <Modal.Title>{companyDetail.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="compbody">
            <div class="card-body">
              {companyDetailLoading ? (
                <div className="tableBox">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        {companyDetailList.topHeader.map((value) => {
                          return <th>{value.name}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {companyDetailList.data.map((value) => {
                        value.data.map((data) => {
                          return <td>{value.name}</td>;
                        });
                      })} */}
                      {companyDetailList.leftHeader.map((value) => {
                        return (
                          <tr>
                            <td>{value.name}</td>
                            {/* {getTdData(
                              value.id,
                              companyDetailList.data,
                              companyDetailList.topHeader
                            )} */}
                            {companyDetailList.data.filter(
                              (tempValue) => tempValue._id == value.id
                            ).length
                              ? companyDetailList.topHeader.map(
                                  (ksdf, index) => {
                                    let dd = companyDetailList.data.filter(
                                      (kij) => {
                                        return kij._id == value.id;
                                      }
                                    );
                                    return dd.map((dk) => {
                                      return (
                                        <td>
                                          {dk.data.find((vd) => {
                                            if (vd.data == ksdf._id) {
                                              return true;
                                            }
                                          })?.count ?? 0}
                                        </td>
                                      );
                                    });
                                  }
                                )
                              : companyDetailList.topHeader.map((value) => {
                                  return <td>0</td>;
                                })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Shimmer />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EngagementBucket;
