import React from "react";
const Shimmer = () => {
  return (
    <>
      {/* <div class="loader"></div> */}
      <div class="shimmertext">
        <span class="loader"></span>
      </div>
    </>
  );
};

export default Shimmer;
