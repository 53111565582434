import flyIcon from "../assets/images/fly.png";
import ccc from "../assets/images/ccc.svg";
import theamesIcon from "../assets/images/theames.svg";
import engagementIcon from "../assets/images/engagement.svg";
import mediaIcon from "../assets/images/media.svg";
import SustainabilityIcon from "../assets/images/Sustainability.svg";
import PostIcon from "../assets/images/View By Post.svg";
import gIcon from "../assets/images/g.svg";
import notification from "../assets/images/notification.svg";
import USERIcon from "../assets/images/USER.svg";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const SideMenu = () => {
  const location = useLocation();
  const [active, setActive] = useState(1);

  const checkActiveStatus = () => {
    switch (location.pathname) {
      case "/":
        setActive(1);
        break;
      case "/theme":
        setActive(2);
        break;
      case "/engagement":
        setActive(3);
        break;
      case "/media":
        setActive(4);
        break;
      default:
        setActive(1);
        break;
    }
  };

  useEffect(() => {
    checkActiveStatus();
  }, [location.pathname]);
  return (
    <div className="sidebar">
      <div className="sideHeader">
        <a href="/">
          <img src={flyIcon} alt="" />
        </a>
      </div>
      <div className="sidebarIner">
        <ul className="sideBarNav">
          <li className={active === 1 ? "active" : ""}>
            <Link to="/">
              <img src={ccc} alt="" width={30} />
            </Link>
          </li>
          <li className={active === 2 ? "active" : ""}>
            <Link to="/theme">
              <img src={theamesIcon} alt="" />
              Themes
            </Link>
          </li>
          <li className={active === 3 ? "active" : ""}>
            <Link to="/engagement">
              <img src={engagementIcon} alt="" />
              Engagement
            </Link>
          </li>
          <li className={active === 4 ? "active" : ""}>
            <Link to="/media">
              <img src={mediaIcon} alt="" />
              Media
            </Link>
          </li>
          {/* <li>
            <a href="#clients">
              <img src={SustainabilityIcon} alt="" />
              Sustainability
            </a>
          </li>
          <li>
            <a href="#clients">
              <img src={PostIcon} alt="" /> View By Post
            </a>
          </li>
          <li>
            <a href="#contact">
              <img src={gIcon} alt="" /> Analysis
            </a>
          </li> */}
        </ul>
        <ul className="sideBarNav navBottom">
          <li>
            <a href="#services">
              <img src={notification} alt="" />
            </a>
          </li>
          <li>
            <a href="#contact">
              <img src={USERIcon} alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
