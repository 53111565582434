import c1Icon from "../../assets/images/chart1.svg";
import cIcon from "../../assets/images/table_icon.svg";
import fluent from "../../assets/images/info_icon.svg";
import carbonImg from "../../assets/images/image_icon.svg";
import pepicon from "../../assets/images/full_view_icon.svg";
import compare from "../../assets/images/compare_icon.svg";
import shrink from "../../assets/images/shrink.png";
import menuO from "../../assets/images/menu-o.png";
import menuC from "../../assets/images/menu-c.png";
import DropBox from "../dropBox";

import { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import {
  fetchAllUniqueByPost,
  fetchByPost,
  fetchCompanyList,
} from "../../services/home";
import { useSelector } from "react-redux";
import Shimmer from "../shimmer";
import { Modal } from "react-bootstrap";
import html2canvas from "html2canvas";
import moment from "momnet";
import { bottom } from "@popperjs/core";

const ByPostCard = ({ startDate, endDate }) => {
  const chartRef = useRef(null);
  const refreshValue = useSelector((state) => state.counter.value);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [compareModel, setCompareModel] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany1, setSelectedCompany1] = useState("");
  const [selectedCompany2, setSelectedCompany2] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const showExpand = () => {
    setIsExpand(!isExpand);
  };

  const toggleInfo = () => {
    setInfoVisible(!isInfoVisible);
  };
  const [chartState, setChartState] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: ["M1", "M2", "M3", "M4"],
        },
      ],
      yAxis: [
        {
          type: "value",
          splitLine: { show: false },
        },
      ],
      series: [
        {
          name: "Unique",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Repeat",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    },
  });

  const [compareStartDate1, setCompareStartDate1] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate1, setCompareEndDate1] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading1, setCompareLoading1] = useState(false);
  const [compareChartState1, setCompareChartState1] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: ["M1", "M2", "M3", "M4"],
        },
      ],
      yAxis: [
        {
          type: "value",
          splitLine: { show: false },
        },
      ],
      series: [
        {
          name: "Unique",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Repeat",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    },
  });

  const [compareStartDate, setCompareStartDate] = useState(
    moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  );
  const [compareEndDate, setCompareEndDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [compareLoading, setCompareLoading] = useState(false);
  const [compareChartState, setCompareChartState] = useState({
    options: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: ["M1", "M2", "M3", "M4"],
        },
      ],
      yAxis: [
        {
          type: "value",
          splitLine: { show: false },
        },
      ],
      series: [
        {
          name: "Unique",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Repeat",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    },
  });

  const getByPost = async () => {
    setLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: startDate,
      endDate: endDate,
    };
    const res = await fetchAllUniqueByPost(payload);
    if (res.status) {
      setTableData(res.data);
      let months = res.data.map((value) => {
        return value._id;
      });

      let uniques = res.data.map((value) => {
        return value.unique;
      });

      let repeat = res.data.map((value) => {
        return value.repeated;
      });

      chartState.options.xAxis[0].data = months;
      chartState.options.series[0].data = uniques;
      chartState.options.series[1].data = repeat;
      setChartState(chartState);

      // let sum = 0;
      // let arrayValue = res.data.map((element) => {
      //   sum = sum + element.count;
      //   return { value: element.count, name: element.name };
      // });
      // arrayValue.push({
      //   // make an record to fill the bottom 50%
      //   value: sum === 0 ? 1 : sum,
      //   itemStyle: {
      //     // stop the chart from rendering this piece
      //     color: "none",
      //     decal: {
      //       symbol: "none",
      //     },
      //   },
      //   label: {
      //     show: false,
      //   },
      // });

      // chartState.options.series[0].data = arrayValue;

      // setChartState(chartState);
      setLoading(true);
    }
  };

  const getCompareByPost1 = async () => {
    setCompareLoading1(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate1,
      endDate: compareEndDate1,
    };
    if (selectedCompany1) {
      payload.companyIds = [selectedCompany1];
    }

    const res = await fetchAllUniqueByPost(payload);
    if (res.status) {
      let months = res.data.map((value) => {
        return value._id;
      });

      let uniques = res.data.map((value) => {
        return value.unique;
      });

      let repeat = res.data.map((value) => {
        return value.repeated;
      });

      compareChartState1.options.xAxis[0].data = months;
      compareChartState1.options.series[0].data = uniques;
      compareChartState1.options.series[1].data = repeat;
      setCompareChartState1(compareChartState1);
      setCompareLoading1(true);
    }
  };

  const getCompareByPost = async () => {
    setCompareLoading(false);
    const payload = {
      handler:
        JSON.parse(localStorage.getItem("company_filter"))?.map(
          (value) => value.id
        ) ?? [],
      themes:
        JSON.parse(localStorage.getItem("themes_filter"))?.map(
          (value) => value.id
        ) ?? [],
      uniqueType: localStorage.getItem("requestType"),
      startDate: compareStartDate,
      endDate: compareEndDate,
    };
    if (selectedCompany2) {
      payload.companyIds = [selectedCompany2];
    }

    const res = await fetchAllUniqueByPost(payload);
    if (res.status) {
      let months = res.data.map((value) => {
        return value._id;
      });

      let uniques = res.data.map((value) => {
        return value.unique;
      });

      let repeat = res.data.map((value) => {
        return value.repeated;
      });

      compareChartState.options.xAxis[0].data = months;
      compareChartState.options.series[0].data = uniques;
      compareChartState.options.series[1].data = repeat;
      setCompareChartState(compareChartState);
      setCompareLoading(true);
    }
  };

  const getCompanyList = async () => {
    const res = await fetchCompanyList();
    if (res.status) {
      setCompanyList(res.data);
    }
  };

  const handleCompareModelClose = () => {
    setCompareStartDate1(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate1(moment(new Date()).format("yyyy-MM-DD"));
    setCompareStartDate(
      moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
    );
    setCompareEndDate(moment(new Date()).format("yyyy-MM-DD"));
    setSelectedCompany1("");
    setSelectedCompany2("");
    setCompareModel(false);
  };

  useEffect(() => {
    getByPost();
  }, [startDate, endDate, refresh, refreshValue]);

  useEffect(() => {
    getCompareByPost1();
  }, [compareEndDate1, compareStartDate1, selectedCompany1]);

  useEffect(() => {
    getCompareByPost();
  }, [compareEndDate, compareStartDate, selectedCompany2]);

  useEffect(() => {
    getCompanyList();
  }, []);

  const saveAsImage = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
      });
    }
  };

  return (
    <>
      {isExpand && <DropBox />}
      <div class={`themeCard ${isExpand ? "expand" : ""}`}>
        <div class="CardContainer">
          <header class="cardHeader">
            <h5>Unique Vs Repeat</h5>
            {/* {isExpand && (
            <button className="closeExpand" onClick={closeExpand}>
              {" "}
              Close
            </button>
          )} */}
            <div class="headerRight">
              <ul class="headerRightItems">
                <li onClick={setRefresh}>
                  {" "}
                  <a>
                    <img src={c1Icon} alt="" />
                  </a>
                </li>
                <li
                  className={`${showTable ? "active" : ""}`}
                  onClick={() => {
                    setShowTable(!showTable);
                  }}
                >
                  {" "}
                  <a>
                    <img src={cIcon} alt="" />
                  </a>
                </li>
                <li
                  onClick={() => {
                    getCompareByPost();
                    setCompareModel(true);
                  }}
                >
                  {" "}
                  <a>
                    <img src={compare} alt="" />
                  </a>
                </li>
                <li onClick={toggleInfo}>
                  {" "}
                  <a>
                    <img src={fluent} alt="" />
                  </a>
                  {isInfoVisible && (
                    <div className="chartInfo">
                      <div className="infottl">Information</div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                    </div>
                  )}
                </li>

                <li
                  onClick={() => {
                    saveAsImage();
                  }}
                >
                  {" "}
                  <a>
                    <img src={carbonImg} alt="" />
                  </a>
                </li>
                <li
                  className={`${isExpand ? "active" : ""}`}
                  onClick={showExpand}
                >
                  {" "}
                  <a>
                    <img src={isExpand ? shrink : pepicon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </header>

          <div class="card-body">
            {loading ? (
              <div ref={chartRef} className="chartBox">
                {showTable ? (
                  <div className="tableBox">
                    <table>
                      <thead>
                        <tr>
                          <th>Month</th>
                          <th>Unique Data</th>
                          <th>Repeat Data</th>
                          <th>Total Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((value) => {
                          return (
                            <tr>
                              <td>{value._id}</td>
                              <td>{value.unique}</td>
                              <td>{value.repeated}</td>
                              <td>{value.totalPost}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <ReactECharts
                    option={chartState.options}
                    style={isExpand ? { height: "600px", width: "100%" } : {}}
                  />
                )}
              </div>
            ) : (
              <Shimmer />
            )}
          </div>
        </div>

        {/* compare model */}
        <Modal
          centered
          show={compareModel}
          onHide={() => {
            handleCompareModelClose();
          }}
          style={{ opacity: 1 }}
          backdrop="static"
          keyboard={false}
          bsSize="large"
          className="modal-lg customModel"
        >
          <Modal.Header closeButton>
            <Modal.Title>Compare</Modal.Title>
          </Modal.Header>
          <Modal.Body className="compbody">
            <div className="compBox">
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen1 ? menuC : menuO}
                        alt=""
                        onClick={() => {
                          setIsMenuOpen1(!isMenuOpen1);
                        }}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen1 ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany1(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate1).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate1(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate1).format("yyyy-MM-DD")}
                          value={moment(compareEndDate1).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate1(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading1 ? (
                    <ReactECharts option={compareChartState1.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
              <div className="seldate">
                <div className="chart1Inner">
                  <div className="compInputBox">
                    <div className="compOpenBox">
                      <img
                        src={isMenuOpen ? menuC : menuO}
                        alt=""
                        onClick={toggleMenu}
                      />
                    </div>
                    <div className={`CompInputs ${isMenuOpen ? "open" : ""}`}>
                      <div class="inputItem">
                        <select
                          class="ipuptThemeDate"
                          onChange={(e) => {
                            setSelectedCompany2(e.target.value);
                          }}
                        >
                          <option>select company</option>
                          {companyList.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="from_date"
                          value={moment(compareStartDate).format("yyyy-MM-DD")}
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareStartDate(e.target.value);
                          }}
                        />
                      </div>
                      <div class="inputItem">
                        <input
                          type="date"
                          class="ipuptThemeDate"
                          name="to_date"
                          min={moment(compareStartDate).format("yyyy-MM-DD")}
                          value={moment(compareEndDate).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            setCompareEndDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {compareLoading ? (
                    <ReactECharts option={compareChartState.options} />
                  ) : (
                    <Shimmer />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ByPostCard;
