import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import ByMediaTypeCard from "../components/homeComponent/byMediaTypeCard";
import ByVideoTypeCard from "../components/homeComponent/byVideoTypeCard";
import ByMediaAndByVideo from "../components/mediaComponent/byMediaAndByVideo";
import AnalysisByThemeMediaType from "../components/mediaComponent/anlayisByThemeMedia";
import ByMediaTypeByVideoType from "../components/mediaComponent/byMediaTypeByVideoType";
import VideoAnalysisByCompany from "../components/mediaComponent/videoAnalysisByCompnay";

const MediaPage = ({ startDate, endDate }) => {
  return (
    <section className="content sec">
      <div class="cardRow twoCol">
        <ByMediaTypeCard startDate={startDate} endDate={endDate} />
        <ByVideoTypeCard startDate={startDate} endDate={endDate} />
      </div>
      <div className="cardRow">
        <ByMediaAndByVideo startDate={startDate} endDate={endDate} />
      </div>
      <div className="cardRow">
        <AnalysisByThemeMediaType startDate={startDate} endDate={endDate} />
      </div>
      <div className="cardRow">
        <ByMediaTypeByVideoType startDate={startDate} endDate={endDate} />
      </div>
      <div className="cardRow">
        <VideoAnalysisByCompany startDate={startDate} endDate={endDate} />
      </div>
      <Footer />
    </section>
  );
};

export default MediaPage;
