import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import PostByTimeDateCard from "../components/engagementComponent/byEngagement";
import ByThemeCard from "../components/engagementComponent/byTheme";
import ByMediaTypeCard from "../components/engagementComponent/byMediaType";
import ByVedioType from "../components/engagementComponent/byVedio";
import EngagementBucket from "../components/engagementComponent/engagementBucket";
import ByPlatformTypeCard from "../components/engagementComponent/byPlatformType";

const EngagementPage = ({ startDate, endDate }) => {
  return (
    <section class="content sec">
      <div class="cardRow">
        <PostByTimeDateCard startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <ByThemeCard startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <ByMediaTypeCard startDate={startDate} endDate={endDate} />
      </div>
      {/* <div class="cardRow">
        <ByVedioType startDate={startDate} endDate={endDate} />
      </div> */}
      <div class="cardRow">
        <ByVedioType startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <ByPlatformTypeCard startDate={startDate} endDate={endDate} />
      </div>
      <div class="cardRow">
        <EngagementBucket startDate={startDate} endDate={endDate} />
      </div>
      <Footer />
    </section>
  );
};

export default EngagementPage;
