import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { refresh } from "../../redux/slice/filterRefreshSlice";

const FilterPage = () => {
  const dispatch = useDispatch();
  const refreshValue = useSelector((state) => state.counter.value);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  useEffect(() => {
    setSelectedThemes(JSON.parse(localStorage.getItem("themes_filter")) ?? []);
    setSelectedCompany(
      JSON.parse(localStorage.getItem("company_filter")) ?? []
    );
  }, [refreshValue]);

  const resetFilter = () => {
    dispatch(refresh());
    localStorage.setItem("company_filter", JSON.stringify([]));
    localStorage.setItem("themes_filter", JSON.stringify([]));
  };

  const onRemoveClickCompany = (value) => {
    localStorage.setItem(
      "company_filter",
      JSON.stringify(
        selectedCompany.filter((data) => {
          return data.id !== value.id;
        })
      )
    );
    dispatch(refresh());
  };

  const onRemoveClickTheme = (value) => {
    localStorage.setItem(
      "themes_filter",
      JSON.stringify(
        selectedThemes.filter((data) => {
          return data.id !== value.id;
        })
      )
    );
    dispatch(refresh());
  };

  return (
    <div class="filterbox">
      <div class="filterBoxLeft">
        <h4>Filter by:</h4>
        <div class="filterclientHandle">
          {selectedCompany.length ? (
            <>
              <h4> Client Handler</h4>
              {selectedCompany &&
                selectedCompany.map((value) => {
                  return (
                    <button class="filterBoxbtn">
                      {" "}
                      <p>{value.value}</p>{" "}
                      <img
                        src="/close.svg"
                        alt=""
                        onClick={() => {
                          onRemoveClickCompany(value);
                        }}
                      />
                    </button>
                  );
                })}
            </>
          ) : (
            <></>
          )}
        </div>
        <div class="filterThemes">
          {selectedThemes.length ? (
            <>
              <h4>Themes</h4>
              {selectedThemes.map((value) => {
                return (
                  <button class="filterBoxbtn">
                    {" "}
                    {value.value}{" "}
                    <img
                      src="/close.svg"
                      alt=""
                      onClick={() => {
                        onRemoveClickTheme(value);
                      }}
                    />
                  </button>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div class="filterBoxRight">
        {selectedCompany.length || selectedThemes.length ? (
          <button
            class="filterBoxbtn"
            onClick={() => {
              resetFilter();
            }}
          >
            Reset
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FilterPage;
